import * as React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentProps,
  makeStyles,
	DialogProps
} from '@material-ui/core';

type Props = {
  title: string,
  open: boolean,
	busy?: boolean,
  onOK: () => void,
	onClose: () => void,
	dialogContentProps?: DialogContentProps
} & DialogProps;

const BaseDialog: React.FC<Props> = (props) => {
  const { title, open, busy, onOK, onClose, dialogContentProps, ...dialogProps } = props;
  const classes = useStyles();
  
  const onCloseDialog = (_: any, reason: string) => {
    if (reason !== 'backdropClick') {
      props.onClose();
    }
  }

  return (
    <Dialog 
      open={open} 
			onClose={onCloseDialog} 
			maxWidth="md"
			fullWidth={true}
      {...dialogProps}
    >
      <DialogTitle>{title}</DialogTitle>
			<DialogContent 
				dividers={true}
				className={classes.dialogContent} 
				{...dialogContentProps}
			>
        {props.children}
      </DialogContent>
      <DialogActions>
        <Button 
          onClick={onClose} 
					disabled={busy}
					tabIndex={-1}
        >
          Cancel
        </Button>
        <Button 
          onClick={onOK} 
          color="primary"
					disabled={busy}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles(theme => ({
	dialogContent: {
		padding: theme.spacing(3)
	}
}));

export default BaseDialog;