import * as React from 'react';
import {
	Box,
	IconButton,
	Paper,
	Tooltip,
	Typography,
	makeStyles
} from '@material-ui/core';
import * as Icon from 'mdi-material-ui';

type Props = {
	name?: string,
	url: string,
	onView: () => void,
	onDelete: () => void
}

const ImageTile: React.FC<Props> = (props) => {
	const classes = useStyles();

	return (
		<Paper className={classes.imagePaper} style={{backgroundImage: `url('${props.url}')`}} elevation={2}>
			<Box className={classes.imageActions}>
				{props.name &&
					<Tooltip title={props.name}>
						<Typography className={classes.imageName} component="span">
							{props.name}
						</Typography>
					</Tooltip>
				}
				<Tooltip title="View Image">
					<IconButton className={classes.actionButton} onClick={props.onView}>
						<Icon.Eye />
					</IconButton>
				</Tooltip>
				<Tooltip title="Delete Image">
					<IconButton className={classes.actionButton} onClick={props.onDelete}>
						<Icon.Delete />
					</IconButton>
				</Tooltip>
			</Box>
		</Paper>
	);
}

const useStyles = makeStyles((theme) => ({
	imagePaper: {
		display: 'flex',
		alignItems: 'flex-end',
		width: 250, 
		height: 250, 
		backgroundPosition: 'center center', 
		backgroundRepeat: 'no-repeat', 
		backgroundSize: 'cover',
		borderRadius: 0
	},
	imageActions: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		height: 60,
		width: '100%',
		backgroundColor: 'rgba(0, 0, 0, 0.5)',
		borderColor: 'rgba(0, 0, 0, 0)',
		borderStyle: 'solid',
		borderWidth: 1
	},
	imageName: {
		marginLeft: theme.spacing(1),
		marginRight: 'auto', 
		color: '#fff',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden'
	},
	actionButton: {
		color: 'rgba(255, 255, 255, 0.54)'
	}
}));

export default ImageTile;