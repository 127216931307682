import * as React from 'react';

export const protectedPath = 'protected/documents';
export const publicPath = 'public/documents';

type Values = { name: string; category: string; subcategory: string };

export const useForm = () => {
  const [dirty, setDirty] = React.useState(false);
  const [name, setName] = React.useState('');
  const [nameError, setNameError] = React.useState<string | null>(null);
  const [category, setCategory] = React.useState('');
  const [categoryError, setCategoryError] = React.useState<string | null>(null);
  const [subcategory, setSubcategory] = React.useState('');
  const [subcategoryError, setSubcategoryError] = React.useState<string | null>(null);
  const valuesRef = React.useRef<Values>();

  const set = (values: Values) => {
    valuesRef.current = values;
    setName(values.name);
    setCategory(values.category);
    setSubcategory(values.subcategory);
  };

  const reset = () => {
    let original = valuesRef.current;
    if (original) {
      setName(original.name);
      setCategory(original.category);
      setSubcategory(original.subcategory);
      setDirty(false);
    }
  };

  const validate = (): boolean => {
    let valid = true;
    if (name.trim().length === 0) {
      setNameError('Name is required');
      valid = false;
    }
    if (category.trim().length === 0) {
      setCategoryError('Category is required');
      valid = false;
    }
    if (subcategory.trim().length === 0) {
      setSubcategoryError('Subcategory is required');
      valid = false;
    }
    return valid;
  };

  return {
    name,
    nameField: {
      variant: 'outlined' as any,
      margin: 'dense' as any,
      fullWidth: true,
      label: 'Name',
      value: name,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
        setNameError(null);
        setDirty(true);
      },
      error: nameError != null,
      helperText: nameError,
    },
    category,
    categoryField: {
      variant: 'outlined' as any,
      margin: 'dense' as any,
      fullWidth: true,
      label: 'Category',
      value: category,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        setCategory(e.target.value);
        setCategoryError(null);
        setDirty(true);
      },
      error: categoryError != null,
      helperText: categoryError,
    },
    subcategory,
    setSubcategoryField: {
      variant: 'outlined' as any,
      margin: 'dense' as any,
      fullWidth: true,
      label: 'Subcategory',
      value: subcategory,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        setSubcategory(e.target.value);
        setSubcategoryError(null);
        setDirty(true);
      },
      error: subcategoryError != null,
      helperText: subcategoryError,
    },
    dirty,
    set,
    reset,
    validate,
  };
};
