import * as React from 'react';
import { Snackbar as MUISnackbar, makeStyles } from '@material-ui/core';
import * as Icon from 'mdi-material-ui';

import { MessageContext, SetMessageContext } from '../context';
import { MessageType, Message } from '../types';

const Snackbar: React.FC = () => {
  const message = React.useContext(MessageContext);
  const setMessage = React.useContext(SetMessageContext);
  const classes = useStyles();

  const onClearMessage = () => {
    setMessage(undefined);
  };

  let duration;
  let messageClass;
  let messageIcon;
  let messageText;
  if (message) {
    let messageObj = message as Message;
    if (messageObj.type) {
      duration = messageObj.duration;
      messageText = messageObj.text;
      switch (messageObj.type) {
        case MessageType.Success:
          messageClass = classes.success;
          messageIcon = <Icon.CheckCircle className={classes.messageIcon} />;
          break;
        case MessageType.Warning:
          messageClass = classes.warning;
          messageIcon = <Icon.Alert className={classes.messageIcon} />;
          break;
        case MessageType.Error:
          messageClass = classes.error;
          messageIcon = <Icon.AlertCircle className={classes.messageIcon} />;
          break;
      }
    } else {
      messageText = message;
    }
    if (!duration) {
      duration = 5000;
    }
  }

  return (
    <MUISnackbar
      open={message ? true : false}
      ContentProps={{
        className: messageClass,
      }}
      autoHideDuration={duration}
      onClick={onClearMessage}
      onClose={(_, reason) => {
        if (reason !== 'clickaway') {
          onClearMessage();
        }
      }}
      message={
        <span className={classes.message}>
          {messageIcon}
          {messageText}
        </span>
      }
    />
  );
};

const useStyles = makeStyles((theme) => ({
  messageIcon: {
    fontSize: 20,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}));

export default Snackbar;
