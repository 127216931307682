import * as React from 'react';
import { Button, Grid, makeStyles, useTheme } from '@material-ui/core';
import { Pencil as EditIcon, Delete as DeleteIcon } from 'mdi-material-ui';
import MaterialTable from 'material-table';
import { useHistory } from 'react-router';
import { getAllDocs, deleteDoc } from '../../firebase/firestore';
import { Collection, Document, MessageType } from '../../types';
import CreateDocumentDialog from './CreateDocumentDialog';
import { SetMessageContext } from '../../context';

const Documents: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const setMessage = React.useContext(SetMessageContext);
  const [createDialogOpen, setCreateDialogOpen] = React.useState(false);
  const [documents, setDocuments] = React.useState<Document[]>([]);

  const onCreated = (document: Document) => {
    setCreateDialogOpen(false);
    editDocument(document);
  };

  const editDocument = (document: Document) => {
    history.push(`/admin/documents/${document.slug}`);
  };

  const deleteDocument = (document: Document) => {
    if (!window.confirm('Are you sure you want to delete this document?')) {
      return;
    }
    deleteDoc(Collection.Documents, document.id!)
      .then(() => {
        setDocuments((docs) => [...docs.filter((d) => d.id !== document.id)]);
        setMessage({ type: MessageType.Success, text: 'Document deleted successfully!' });
      })
      .catch(() => {
        setMessage({ type: MessageType.Error, text: 'Delete document failed, please refresh the page and try again!' });
      });
  };

  React.useEffect(() => {
    getAllDocs(Collection.Documents).then((docs) => {
      setDocuments(docs as Document[]);
    });
  }, []);

  return (
    <>
      <CreateDocumentDialog
        open={createDialogOpen}
        onClose={() => {
          setCreateDialogOpen(false);
        }}
        onCreated={onCreated}
      />
      <Grid container>
        <Grid item xs={12} className={classes.gridRow}>
          <Button
            variant="contained"
            disableElevation
            color="primary"
            className={classes.button}
            onClick={() => setCreateDialogOpen(true)}
          >
            New Document
          </Button>
        </Grid>
        <Grid item xs={12} className={classes.gridRow}>
          <MaterialTable
            title="Documents"
            style={{ borderRadius: 0 }}
            columns={[
              { title: 'Name', field: 'name', defaultSort: 'asc' },
              { title: 'Category', field: 'category' },
              { title: 'Subcategory', field: 'subcategory' },
              {
                title: 'Protected',
                field: 'protected',
                type: 'boolean',
              },
              { title: 'Filename', field: 'filename' },
            ]}
            data={documents}
            options={{
              headerStyle: {
                paddingLeft: theme.spacing(2),
              },
              pageSize: 10,
              pageSizeOptions: [10, 20, 50],
              sorting: true,
            }}
            localization={{
              body: {
                emptyDataSourceMessage: 'No documents to display',
              },
            }}
            actions={[
              {
                icon: () => <EditIcon />,
                tooltip: 'Edit Document',
                onClick: (_, document) => editDocument(document as Document),
              },
              {
                icon: () => <DeleteIcon />,
                tooltip: 'Delete Document',
                onClick: (_, document) => deleteDocument(document as Document),
              },
            ]}
          />
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  gridRow: {
    marginBottom: theme.spacing(2),
  },
  button: {
    marginRight: theme.spacing(1),
  },
}));

export default Documents;
