import * as React from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: ${({ theme }) => theme.spacing(1)}px;
  }
`;

type Props = {
  title: string;
  icon?: React.ReactNode;
};

export const CardTitle: React.FC<Props> = (props) => {
  const { icon, title } = props;
  return (
    <HeaderTitle>
      {icon}
      <Typography variant="h5">{title}</Typography>
    </HeaderTitle>
  );
};
