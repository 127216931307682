import * as React from 'react';
import styled from 'styled-components';
import { Collapse, Divider, Drawer, List as MuiList, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import * as Icon from 'mdi-material-ui';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';

import { MenuItem, MenuType } from '../types';

const List = styled(MuiList)`
  width: 300px;
` as typeof MuiList;

const ChildListItem = styled(ListItem)`
  padding-left: ${({ theme }) => theme.spacing(10)}px;
`;

type Props = {
  menu: MenuItem[];
  open: boolean;
  onClose?: () => void;
};

type ExpandState = { type: number; expanded: boolean };

export const SideMenu: React.FC<Props> = (props) => {
  const { menu, open, onClose } = props;
  const history = useHistory();
  const [expandState, setExpandState] = React.useState<ExpandState[]>([]);

  React.useEffect(() => {
    const expand: ExpandState[] = [];
    menu.forEach((menuItem) => {
      if (menuItem.children && menuItem.children.length > 0) {
        expand.push({
          type: menuItem.type,
          expanded: false,
        });
      }
    });
    setExpandState(expand);
  }, [menu]);

  function close() {
    if (onClose) {
      onClose();
    }
  }

  function navigate(path: string) {
    history.push(path);
    close();
  }

  function onClick(menuItem: MenuItem) {
    if (menuItem.path) {
      navigate(menuItem.path);
    } else if (menuItem.children && menuItem.children.length > 0) {
      setExpandState((current) => {
        const updated = [...current];
        const index = updated.findIndex((e) => e.type === menuItem.type);
        if (index > -1) {
          updated[index].expanded = !updated[index].expanded;
        }
        return [...updated];
      });
    }
  }

  return (
    <Drawer open={open} onClose={close}>
      <List component="div">
        {menu.map((menuItem) => {
          const isParent = menuItem.children && menuItem.children.length > 0;
          return (
            <React.Fragment key={menuItem.type}>
              <ListItem button onClick={() => onClick(menuItem)}>
                <ListItemIcon>
                  {menuItem.type === MenuType.Home && <Icon.Home />}
                  {menuItem.type === MenuType.Information && <Icon.InformationOutline />}
                  {menuItem.type === MenuType.Awards && <Icon.Seal />}
                  {menuItem.type === MenuType.Products && <Icon.TagMultiple />}
                  {menuItem.type === MenuType.Documents && <Icon.File />}
                  {menuItem.type === MenuType.Gallery && <Icon.ImageMultiple />}
                  {menuItem.type === MenuType.ContactInfo && <Icon.Phone />}
                </ListItemIcon>
                <ListItemText primary={menuItem.title} />
              </ListItem>
              {isParent && (
                <Collapse
                  in={expandState.find((e) => e.type === menuItem.type)?.expanded ?? false}
                  timeout="auto"
                  unmountOnExit
                >
                  <Divider />
                  <MuiList component="div" disablePadding>
                    {menuItem.children!.map((childMenuItem) => (
                      <ChildListItem key={childMenuItem.type} button onClick={() => onClick(childMenuItem)}>
                        <ListItemText primary={childMenuItem.title} />
                      </ChildListItem>
                    ))}
                  </MuiList>
                  <Divider />
                </Collapse>
              )}
            </React.Fragment>
          );
        })}
      </List>
    </Drawer>
  );
};
