import React from 'react';

type Values = { name: string, description: string };

export const useForm = () => {
	const [dirty, setDirty] = React.useState(false);
  const [name, setName] = React.useState('');
	const [nameError, setNameError] = React.useState<string | null>(null);
	const [description, setDescription] = React.useState('');
	const [descriptionError, setDescriptionError] = React.useState<string | null>(null);
	const valuesRef = React.useRef<Values>();
	
	const set = (values: Values) => {
		valuesRef.current = values;
		setName(values.name);
		setDescription(values.description);
	}

	const reset = () => {
		let original = valuesRef.current;
		if (original) {
			setName(original.name);
			setDescription(original.description);
			setDirty(false);
		}
	}

  const validate = (): boolean => {
		let valid = true;
		if (name.trim().length === 0) {
			setNameError('Name is required');
			valid = false;
		}
		return valid;
	}

  return {
		name,
    nameField: {
			variant: 'outlined' as any,
			margin: 'dense' as any,
			fullWidth: true,
			label: 'Name',
			value: name,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value)
				setNameError(null);
				setDirty(true);
      },
      error: nameError != null,
      helperText: nameError
		},
		description,
		descriptionField: {
			variant: 'outlined' as any,
			margin: 'dense' as any,
			fullWidth: true,
			label: 'Description',
			value: description,
			onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        setDescription(e.target.value)
				setDescriptionError(null);
				setDirty(true);
      },
      error: descriptionError != null,
      helperText: descriptionError
		},
		dirty,
		set,
		reset,
    validate
  }
}