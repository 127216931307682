import 'firebase/auth';
import React from 'react';
import app from 'firebase/app';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { StylesProvider, ThemeProvider as MuiThemeProvider } from '@material-ui/core';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { theme } from './theme';
import LayoutRoute from './shared/LayoutRoute';
import Login from './login/Login';
import Layout from './shared/Layout';
import { Layout as AdminLayout } from './admin/shared/Layout';
import ManufacturesProvider from './shared/ManufacturesProvider';
import EditHome from './admin/home/Home';
import EditPages from './admin/pages/Pages';
import EditManfactures from './admin/manufactures/Manufactures';
import EditManfacture from './admin/manufactures/EditManufacture';
import EditProudcts from './admin/products/Products';
import EditProduct from './admin/products/EditProduct';
import EditAwards from './admin/awards/Awards';
import EditAward from './admin/awards/EditAward';
import EditGalleries from './admin/galleries/Galleries';
import EditGallery from './admin/galleries/EditGallery';
import EditDocuments from './admin/documents/Documents';
import EditDocument from './admin/documents/EditDocument';
import EditVideos from './admin/videos/Videos';
import { Manufactures } from './products/Manufactures';
import Galleries from './galleries/Galleries';
import Awards from './awards/Awards';
import Documents from './documents/Documents';
import { Videos } from './videos/Videos';
import { Home } from './home/Home';
import { Contact } from './contact/Contact';
import { Page } from './shared/Page';
import { MessageContext, SetMessageContext, UserContext } from './context';
import { Message } from './types';

const App: React.FC = () => {
  const [user, setUser] = React.useState<app.User | null | undefined>(undefined);
  const [message, setMessage] = React.useState<string | Message | undefined>();

  const auth = app.auth();
  auth.onIdTokenChanged((u) => {
    setUser(u);
  });

  return (
    <UserContext.Provider value={user}>
      <StylesProvider injectFirst>
        <MuiThemeProvider theme={theme}>
          <StyledThemeProvider theme={theme}>
            <MessageContext.Provider value={message}>
              <SetMessageContext.Provider value={setMessage}>
                <Router>
                  <Switch>
                    <LayoutRoute layout={AdminLayout} path="/admin/home" authorized>
                      <EditHome />
                    </LayoutRoute>
                    <LayoutRoute layout={AdminLayout} path="/admin/pages" authorized>
                      <EditPages />
                    </LayoutRoute>
                    <LayoutRoute layout={AdminLayout} path="/admin/manufactures/:slug" authorized>
                      <EditManfacture />
                    </LayoutRoute>
                    <LayoutRoute layout={AdminLayout} path="/admin/manufactures" authorized>
                      <EditManfactures />
                    </LayoutRoute>
                    <LayoutRoute layout={AdminLayout} path="/admin/products/:slug" authorized>
                      <ManufacturesProvider>
                        <EditProduct />
                      </ManufacturesProvider>
                    </LayoutRoute>
                    <LayoutRoute layout={AdminLayout} path="/admin/products" authorized>
                      <ManufacturesProvider>
                        <EditProudcts />
                      </ManufacturesProvider>
                    </LayoutRoute>
                    <LayoutRoute layout={AdminLayout} path="/admin/awards/:slug" authorized>
                      <EditAward />
                    </LayoutRoute>
                    <LayoutRoute layout={AdminLayout} path="/admin/awards" authorized>
                      <EditAwards />
                    </LayoutRoute>
                    <LayoutRoute layout={AdminLayout} path="/admin/galleries/:slug" authorized>
                      <EditGallery />
                    </LayoutRoute>
                    <LayoutRoute layout={AdminLayout} path="/admin/galleries" authorized>
                      <EditGalleries />
                    </LayoutRoute>
                    <LayoutRoute layout={AdminLayout} path="/admin/documents/:slug" authorized>
                      <EditDocument />
                    </LayoutRoute>
                    <LayoutRoute layout={AdminLayout} path="/admin/documents" authorized>
                      <EditDocuments />
                    </LayoutRoute>
                    <LayoutRoute layout={AdminLayout} path="/admin/videos" authorized>
                      <EditVideos />
                    </LayoutRoute>
                    <Route path="/admin">
                      <Login />
                    </Route>
                    <LayoutRoute title="Information" layout={Layout} path="/information/:slug">
                      <Page />
                    </LayoutRoute>
                    <LayoutRoute title="Products" layout={Layout} path="/products/:manufacture?/:slug?">
                      <Manufactures />
                    </LayoutRoute>
                    <LayoutRoute title="Awards" layout={Layout} path="/awards">
                      <Awards />
                    </LayoutRoute>
                    <LayoutRoute title="Gallery" layout={Layout} path="/galleries/:slug?">
                      <Galleries />
                    </LayoutRoute>
                    <LayoutRoute title="Videos" layout={Layout} path="/videos">
                      <Videos />
                    </LayoutRoute>
                    <LayoutRoute title="Documents" layout={Layout} path="/documents">
                      <Documents />
                    </LayoutRoute>
                    <LayoutRoute title="Contact Us" layout={Layout} path="/contact">
                      <Contact />
                    </LayoutRoute>
                    <LayoutRoute title="Home" layout={Layout} path="/">
                      <Home />
                    </LayoutRoute>
                  </Switch>
                </Router>
              </SetMessageContext.Provider>
            </MessageContext.Provider>
          </StyledThemeProvider>
        </MuiThemeProvider>
      </StylesProvider>
    </UserContext.Provider>
  );
};

export default App;
