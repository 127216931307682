import * as React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, makeStyles, DialogActions } from '@material-ui/core';
import { uploadFile } from '../../firebase/storage';
import FileUpload, { Upload, UploadState } from './FileUpload';

type Props = {
  title: string;
  dragText?: string;
  path: string;
  filename?: string;
  open: boolean;
  multiple?: boolean;
  accept?: string;
  allowClear?: boolean;
  onClose?: (filesUploaded: boolean) => void;
  docID?: string;
  onUpload?: (upload: Upload[]) => void;
  onUploadCompleted?: (upload: Upload, path: string) => void;
};

const UploadDialog: React.FC<Props> = (props) => {
  const { onUpload } = props;
  const classes = useStyles();
  const filesUploadedRef = React.useRef(false);
  let clearAll = React.useRef<() => void>();
  const [showClearAll, setShowClearAll] = React.useState(false);

  const setClearAll = (clearAllFunc: () => void) => {
    clearAll.current = () => {
      clearAllFunc();
      setShowClearAll(false);
    };
  };

  const handleClose = (_?: {}, reason?: 'backdropClick' | 'escapeKeyDown') => {
    if (props.onClose && reason !== 'backdropClick') {
      props.onClose(filesUploadedRef.current);
    }
  };

  const onFileUpload = (uploads: Upload[]) => {
    setShowClearAll(false);
    onUpload?.(uploads);
    let customMetadata = props.docID ? { docID: props.docID } : null;
    uploads.forEach((upload) => {
      let uploadTask = uploadFile(props.path, upload.file, upload.filename, customMetadata, (progress: number) => {
        upload.set({ progress });
      });
      uploadTask
        .then(() => {
          upload.set({ uploadState: UploadState.Completed, text: 'Uploaded successfully!' });
          filesUploadedRef.current = true;
          if (props.onUploadCompleted) {
            props.onUploadCompleted(upload, props.path);
          }
        })
        .catch((error) => {
          let uploadState =
            error.code && error.code.indexOf('canceled') > 0 ? UploadState.Cancelled : UploadState.Failed;
          let text;
          if (error.message) {
            let errorMsg = error.message.split(':');
            text = errorMsg[errorMsg.length > 1 ? 1 : 0].trim();
          } else {
            text = 'Upload failed, please try again.';
          }
          upload.set({ uploadState, progress: 100, text });
        })
        .finally(() => {
          if (
            uploads.filter((u) => u.uploadState === UploadState.Idle || u.uploadState === UploadState.Uploading)
              .length === 0
          ) {
            setShowClearAll(true);
          }
        });
      upload.cancel = () => uploadTask.cancel();
      upload.set({ uploadState: UploadState.Uploading, text: 'Uploading, please wait...' });
    });
  };

  return (
    <Dialog fullWidth maxWidth="sm" onClose={handleClose} open={props.open}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent dividers>
        <FileUpload
          dragText={props.dragText}
          onUpload={onFileUpload}
          allowClear={props.allowClear}
          setClearAll={setClearAll}
          multiple={props.multiple}
          accept={props.accept}
        />
      </DialogContent>
      <DialogActions>
        {props.allowClear && showClearAll && (
          <Button className={classes.clearAllActionButton} onClick={() => clearAll.current!()} color="primary">
            Clear All
          </Button>
        )}
        <Button onClick={handleClose} color="primary">
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  clearAllActionButton: {
    marginRight: 'auto',
  },
}));

export default UploadDialog;
