import React from 'react';
import { Product } from '../../types';
import { ManufacturesContext } from '../../shared/ManufacturesProvider';

type SetState<T> = React.Dispatch<React.SetStateAction<T>>;

export const useForm = () => {
  const [dirty, setDirty] = React.useState(false);
  const [name, setName] = React.useState('');
  const [nameError, setNameError] = React.useState<string | null>(null);
  const [manufacture, setManufacture] = React.useState<firebase.firestore.DocumentReference>();
  const [manufactureError, setManufactureError] = React.useState<string | null>(null);
  const [category, setCategory] = React.useState('');
  const [categoryError, setCategoryError] = React.useState<string | null>(null);
  const [type, setType] = React.useState('');
  const [typeError, setTypeError] = React.useState<string | null>(null);
  const [content, setContent] = React.useState('');
  const manufactures = React.useContext(ManufacturesContext);
  const productRef = React.useRef<Product>();

  const set = (product: Product) => {
    productRef.current = product;
    setName(product.name);
    setManufacture(product.manufacture);
    setCategory(product.category);
    setType(product.type);
    setContent(product.content);
  };

  const reset = () => {
    let original = productRef.current;
    if (original) {
      setName(original.name);
      setManufacture(original.manufacture);
      setCategory(original.category);
      setType(original.type);
      setContent(original.content);
      setDirty(false);
    }
  };

  const validate = (): boolean => {
    let valid = true;
    if (name.trim().length === 0) {
      setNameError('Name is required');
      valid = false;
    }
    if (!manufacture) {
      setManufactureError('Manufacture is required');
      valid = false;
    }
    if (category.trim().length === 0) {
      setCategoryError('Category is required.');
      valid = false;
    }
    if (type.trim().length === 0) {
      setTypeError('Type is required');
      valid = false;
    }
    return valid;
  };

  const createField = (
    label: string,
    field: string,
    setField: SetState<string>,
    fieldError: string | null,
    setFieldError: SetState<string | null>
  ) => ({
    variant: 'outlined' as any,
    margin: 'dense' as any,
    fullWidth: true,
    label,
    value: field,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      setField(e.target.value);
      setFieldError(null);
      setDirty(true);
    },
    helperText: fieldError,
    error: fieldError != null,
  });

  return {
    id: productRef.current ? productRef.current.id : undefined,
    name,
    nameField: createField('Name', name, setName, nameError, setNameError),
    manufacture,
    manufactureField: {
      margin: 'dense',
      fullWidth: true,
      label: 'Manufacture',
      value: manufacture ? manufacture.id : '',
      values: manufactures.map((m) => ({ text: m.name, value: m.id! })),
      onChange: (value: string) => {
        let result = manufactures.find((m) => m.id === value);
        setManufacture(result ? result.ref : null);
        setManufactureError(null);
        setDirty(true);
      },
      helperText: manufactureError,
      error: manufactureError != null,
    },
    category,
    categoryField: createField('Category', category, setCategory, categoryError, setCategoryError),
    type,
    typeField: createField('Type', type, setType, typeError, setTypeError),
    description: content,
    setDescription: (desc: string) => {
      setContent(desc);
      setDirty(true);
    },
    manufactures,
    dirty,
    setDirty,
    set,
    reset,
    validate,
  };
};
