import 'draft-js/dist/Draft.css';
import * as React from 'react';
import styled from 'styled-components';
import { Button, Divider as MuiDivider, Paper as MuiPaper } from '@material-ui/core';
import {
  CompositeDecorator,
  ContentBlock,
  EditorState,
  Modifier,
  RichUtils,
  convertFromRaw,
  convertToRaw,
} from 'draft-js';
import DraftEditor, { composeDecorators } from 'draft-js-plugins-editor';
import createAlignmentPlugin from 'draft-js-alignment-plugin';
import createFocusPlugin from 'draft-js-focus-plugin';
import createResizeablePlugin from 'draft-js-resizeable-plugin';
import createImagePlugin from 'draft-js-image-plugin';

import { setBlockData, getSelectedBlock } from './utils';
import { BlockStyleControls } from './BlockStyleControls';
import { InlineStyleControls } from './InlineStyleControls';
import { AlignmentControls } from './AlignmentControls';
import { ImageControl } from './ImageControl';
import { LinkControl, linkDecorator } from './LinkControl';

// #region styles

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ToolbarPaper = styled(MuiPaper)`
  ${({ theme }) => `
    flex: 0;
    display: flex;
    flex-wrap: wrap;
    border: 1px solid ${theme.palette.divider};
    button {
      border: none;
      margin: ${theme.spacing(0.5)}px;
      &:not(:first-child) {
        border-radius: ${theme.shape.borderRadius}px;
        margin-left: 0;
      }
      &:first-child {
        border-radius: ${theme.shape.borderRadius}px;
      }
    }    
  `}
`;

const Paper = styled(MuiPaper)`
  ${({ theme }) => `
    flex: 1;
    overflow: auto;
    border: 1px solid ${theme.palette.divider};
    padding: ${theme.spacing(1)}px;
    overflow: auto;
    height: 400px;

    .RichEditor-focused {
      opacity: 0.75;
      border: 1px solid ${theme.palette.grey[400]};
    }
  `}
`;

const Divider = styled(MuiDivider)`
  margin: ${({ theme }) => theme.spacing(1, 0.5)};
`;

// #endregion

const alignmentPlugin = createAlignmentPlugin();
const focusPlugin = (createFocusPlugin as any)({ theme: { focused: 'RichEditor-focused' } });
// const resizeablePlugin = createResizeablePlugin();
const { AlignmentTool } = alignmentPlugin;
const decorator = composeDecorators(focusPlugin.decorator);
const imagePlugin = createImagePlugin({ decorator });
const plugins = [focusPlugin, imagePlugin];

export type EditorRef = {
  getJson(): string;
};

type Props = {
  value: string;
  imagePath: string;
  onChange?: () => void;
  className?: string;
};

export const Editor = React.forwardRef<EditorRef, Props>((props, ref) => {
  const { className, value, imagePath, onChange } = props;
  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty(new CompositeDecorator([linkDecorator]))
  );
  const draftEditor = React.useRef<DraftEditor>(null);
  const keepFocus = React.useRef(true);
  const forceSelection = React.useRef(false);

  React.useImperativeHandle(ref, () => ({
    getJson: () => {
      const raw = convertToRaw(editorState.getCurrentContent());
      return JSON.stringify(raw);
    },
  }));

  React.useEffect(() => {
    if (value) {
      try {
        const contentState = convertFromRaw(JSON.parse(value));
        setEditorState(EditorState.createWithContent(contentState));
        return;
      } catch {}
    }
    setEditorState(EditorState.createEmpty());
  }, [value]);

  React.useEffect(() => {
    // Use to just provide a notification that the state is dirty.
    onChange?.();
    console.log('change');
    if (forceSelection.current === true) {
      // focus.current = false;
      const selection = editorState.getSelection();
      const block = getSelectedBlock(editorState);
      console.log(block);
      if (block && block.getType() !== 'atomic') {
        forceSelection.current = false;
        setEditorState((editor) => EditorState.forceSelection(editor, editor.getSelection()));
      }
      // Slight delay to allow things to settle.
      // console.log(draftEditor.current);
      // const element = document.getElementsByClassName('RichEditor-focused');
      // if (element.length > 0) {
      //   // setTimeout(() => (element[0] as HTMLElement).blur(), 500);
      //   setEditorState((editor) => EditorState.forceSelection(editor, editor.getSelection()));
      // }
      // setTimeout(() => draftEditor.current?.focus(), 50);
    }
  }, [editorState]);

  const onTab = (e: React.KeyboardEvent<{}>) => {
    const maxDepth = 4;
    setEditorState((editor) => RichUtils.onTab(e, editor, maxDepth));
  };

  const onKeyCommand = (command: string, editorState: EditorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  const getBlockStyle = (block: ContentBlock) => {
    if (block.getType() === 'blockquote') {
      return 'RichEditor-blockquote';
    }
    const alignment = block.getData() && block.getData().get('alignment');
    if (alignment) {
      return `RichEditor-align-${alignment}`;
    }
    return '';
  };

  const onBlockTypeChange = (blockType: string) => {
    setEditorState((editor) => RichUtils.toggleBlockType(editor, blockType));
  };

  const onToggleInlineStyle = (inlineStyle: string) => {
    setEditorState((editor) => RichUtils.toggleInlineStyle(editor, inlineStyle));
  };

  const onAlignmentChange = (alignment: string) => {
    const block = getSelectedBlock(editorState);
    if (block) {
      setEditorState((editor) => {
        if (block.getType() === 'atomic') {
          forceSelection.current = true;
          const entityKey = block.getEntityAt(0);
          const contentState = editor.getCurrentContent();
          contentState.mergeEntityData(entityKey, { alignment: alignment });
        }
        return setBlockData(editor, { alignment });
      });
    }
  };

  const onImageUploaded = (url: string) => {
    setEditorState((editorState) => imagePlugin.addImage(editorState, url, { width: 'auto', height: 'auto', alt: '' }));
  };

  const onLink = (url: string | null) => {
    setEditorState((editorState) => {
      const selection = editorState.getSelection();
      if (selection.isCollapsed()) {
        return editorState;
      }
      let editor = editorState;
      let entityKey = null;
      if (url) {
        const content = editorState.getCurrentContent();
        const contentWithEntity = content.createEntity('LINK', 'MUTABLE', { url });
        editor = EditorState.push(editorState, contentWithEntity, 'apply-entity');
        entityKey = contentWithEntity.getLastCreatedEntityKey();
      }
      return RichUtils.toggleLink(editor, selection, entityKey);
    });
  };

  const onPaperClicked = () => {
    // editor.current?.focus();
  };

  const onToolModelOpening = () => {
    keepFocus.current = false;
  };

  const onToolModelClosing = () => {
    keepFocus.current = true;
  };

  return (
    <Container
      className={className}
      onFocus={(e) => {
        if (!keepFocus.current) {
          console.log('not focusing');
          return;
        }
        const block = getSelectedBlock(editorState);
        if (block && block.getType() !== 'atomic' && draftEditor.current) {
          try {
            console.log('focusing');
            draftEditor.current.focus();
          } catch {}
        }
      }}
    >
      <ToolbarPaper square elevation={0}>
        <InlineStyleControls editorState={editorState} onToggle={onToggleInlineStyle} />
        <Divider flexItem orientation="vertical" />
        <BlockStyleControls editorState={editorState} onChange={onBlockTypeChange} />
        <Divider flexItem orientation="vertical" />
        <AlignmentControls editorState={editorState} onChange={onAlignmentChange} />
        <Divider flexItem orientation="vertical" />
        <ImageControl
          path={imagePath}
          onOpening={onToolModelOpening}
          onClosing={onToolModelClosing}
          onUpload={onImageUploaded}
        />
        <LinkControl
          editorState={editorState}
          onLink={onLink}
          onOpening={onToolModelOpening}
          onClosing={onToolModelClosing}
        />
      </ToolbarPaper>
      <Paper square elevation={0} onClick={onPaperClicked}>
        <DraftEditor
          ref={draftEditor}
          blockStyleFn={getBlockStyle}
          onTab={onTab}
          spellCheck
          editorState={editorState}
          onChange={setEditorState}
          plugins={plugins}
          handleKeyCommand={onKeyCommand}
          decorators={[linkDecorator]}
        />
      </Paper>
    </Container>
  );
});
