import * as React from 'react';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import * as Icons from 'mdi-material-ui';
import { EditorState } from 'draft-js';

const BLOCK_TYPES = [
  { label: 'H1', style: 'header-one' },
  { label: 'H2', style: 'header-two' },
  { label: 'H3', style: 'header-three' },
  { label: 'H4', style: 'header-four' },
  { label: 'H5', style: 'header-five' },
  { label: 'H6', style: 'header-six' },
  { label: 'Blockquote', style: 'blockquote' },
  { label: 'UL', style: 'unordered-list-item' },
  { label: 'OL', style: 'ordered-list-item' },
];

type Props = {
  editorState: EditorState;
  onChange: (blockType: string) => void;
};

export const BlockStyleControls: React.FC<Props> = (props) => {
  const { editorState, onChange } = props;
  const selection = editorState.getSelection();
  const blockType = editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType();

  return (
    <ToggleButtonGroup
      exclusive
      value={blockType}
      onChange={(_, value) => onChange(value)}
      aria-label="text formatting"
    >
      {BLOCK_TYPES.map((t, i) => (
        <ToggleButton key={t.style} value={t.style} aria-label={t.label}>
          {i === 0 && <Icons.FormatHeader1 />}
          {i === 1 && <Icons.FormatHeader2 />}
          {i === 2 && <Icons.FormatHeader3 />}
          {i === 3 && <Icons.FormatHeader4 />}
          {i === 4 && <Icons.FormatHeader5 />}
          {i === 5 && <Icons.FormatHeader6 />}
          {i === 6 && <Icons.FormatQuoteOpen />}
          {i === 7 && <Icons.FormatListBulleted />}
          {i === 8 && <Icons.FormatListNumbered />}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
