import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
	'@global': {
		'.MuiTable-root .MuiIconButton-label': {
			opacity: 0.54
		},
		'.divider-item': {
			paddingTop: '0 !important'
		}
	}
});

export default useStyles;