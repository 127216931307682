import * as React from 'react';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import * as Icons from 'mdi-material-ui';
import { EditorState } from 'draft-js';

const alignments = [
  { label: 'align left', style: 'left' },
  { label: 'align center', style: 'center' },
  { label: 'align right', style: 'right' },
];

type Props = {
  editorState: EditorState;
  onChange: (alignment: string) => void;
};

export const AlignmentControls: React.FC<Props> = (props) => {
  const { editorState, onChange } = props;
  const selection = editorState.getSelection();
  const block = editorState.getCurrentContent().getBlockForKey(selection.getStartKey());
  const alignment = block.getData().get('alignment');

  return (
    <ToggleButtonGroup
      exclusive
      value={alignment ?? alignments[0].style}
      onChange={(_, value) => onChange(value)}
      aria-label="text alignment"
    >
      {alignments.map((t, i) => (
        <ToggleButton key={t.style} value={t.style} aria-label={t.label}>
          {i === 0 && <Icons.AlignHorizontalLeft />}
          {i === 1 && <Icons.AlignHorizontalCenter />}
          {i === 2 && <Icons.AlignHorizontalRight />}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
