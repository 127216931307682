import * as React from 'react';
import styled from 'styled-components';
import { Button, MobileStepper, Paper as MuiPaper, Typography } from '@material-ui/core';
import { ChevronLeft as PrevIcon, ChevronRight as NextIcon } from 'mdi-material-ui';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import { Gallery as GalleryDefinition } from '../types';

// const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const AutoPlaySwipeableViews = SwipeableViews;

// #region styles

const Paper = styled(MuiPaper)`
  ${({ theme }) => `
    flex-grow: 1;
    align-self: center;
    max-width: 600px;
    padding: ${theme.spacing(2)}px;
  `}
`;

const HeaderContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

const Header = styled(Typography)`
  // margin: ${({ theme }) => theme.spacing(2, 4, 0, 4)};
  background-color: ${({ theme }) => theme.palette.background.paper};
` as typeof Typography;

const SubHeader = styled(Typography)`
  // margin: ${({ theme }) => theme.spacing(1, 4, 2, 4)};
  background-color: ${({ theme }) => theme.palette.background.paper};
` as typeof Typography;

const ImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: center;
  height: 100%;
`;

const Img = styled.img`
  max-height: 600px;
  display: block;
  max-width: 100%;
  overflow: hidden;
`;

const Stepper = styled(MobileStepper)`
  background-color: ${({ theme }) => theme.palette.background.paper};
`;

// #endregion

type Props = {
  gallery: GalleryDefinition;
};

export const Gallery: React.FC<Props> = (props) => {
  const { gallery } = props;
  const [activeStep, setActiveStep] = React.useState(0);
  const images = gallery.images ?? [];
  const maxSteps = images.length;

  const onNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const onPrev = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const onStepChange = (step: number) => {
    setActiveStep(step);
  };

  if (activeStep >= images.length) {
    return null;
  }

  return (
    <Paper square>
      <HeaderContainer>
        <Header component="h1" variant="h5">
          {images[activeStep].name ? images[activeStep].name : gallery.name}
        </Header>
        {images[activeStep].description && (
          <SubHeader variant="caption" component="p" color="textSecondary">
            {images[activeStep].description}
          </SubHeader>
        )}
      </HeaderContainer>
      <AutoPlaySwipeableViews index={activeStep} onChangeIndex={onStepChange} enableMouseEvents>
        {images.map((image, index) => (
          <ImgWrapper key={image.name}>{Math.abs(activeStep - index) <= 2 ? <Img src={image.url} /> : null}</ImgWrapper>
        ))}
      </AutoPlaySwipeableViews>
      <Stepper
        steps={maxSteps}
        position="static"
        variant={images.length > 9 ? 'text' : 'dots'}
        activeStep={activeStep}
        nextButton={
          <Button size="small" onClick={onNext} disabled={activeStep === maxSteps - 1} endIcon={<NextIcon />}>
            Next
          </Button>
        }
        backButton={
          <Button size="small" onClick={onPrev} disabled={activeStep === 0} startIcon={<PrevIcon />}>
            Back
          </Button>
        }
      />
    </Paper>
  );
};
