import * as React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import {
  Button,
  ClickAwayListener,
  Container,
  Grid,
  Grow,
  IconButton,
  Hidden,
  MenuList,
  Paper,
  Popper,
  MenuItem as MUIMenuItem,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as Icon from 'mdi-material-ui';
import { getAllDocs } from '../firebase/firestore';
import { Collection, MenuItems, MenuItem, MenuType } from '../types';
import { SideMenu } from './SideMenu';

const MenuIconButton = styled(IconButton)`
  color: ${({ theme }) => theme.palette.primary.contrastText};
`;

const defaultMenu: MenuItem[] = [
  { type: MenuType.Home, title: 'Home', path: '/' },
  { type: MenuType.Information, title: 'Information', children: [] },
  { type: MenuType.Products, title: 'Products', path: '/products' },
  { type: MenuType.Awards, title: 'Awards', path: '/awards' },
  {
    type: MenuType.Gallery,
    title: 'Gallery',
    children: [
      { type: MenuType.Photos, title: 'Photos', path: '/galleries' },
      { type: MenuType.Videos, title: 'Videos', path: '/videos' },
    ],
  },
  { type: MenuType.Documents, title: 'Documents', path: '/documents' },
  { type: MenuType.ContactInfo, title: 'Contact Info', path: '/contact' },
];

const Menu: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<HTMLElement | null>();
  const [menuItems, setMenuItems] = React.useState<MenuItem[]>([]);
  const [menu, setMenu] = React.useState<MenuItem[]>(defaultMenu);
  const buttonRefs = React.useRef<{ type: MenuType; ref: HTMLButtonElement | null }[]>([]);

  function navigate(path: string) {
    setMenuOpen(false);
    history.push(path);
  }

  function openSubMenu(menuType: MenuType) {
    if (!menu) {
      return;
    }
    switch (menuType) {
      case MenuType.Information:
        setMenuAnchorEl(buttonRefs.current.find((m) => m.type === MenuType.Information)?.ref);
        setMenuItems(menu.find((m) => m.type === MenuType.Information)!.children!);
        break;
      case MenuType.Gallery:
        setMenuAnchorEl(buttonRefs.current.find((m) => m.type === MenuType.Gallery)?.ref);
        setMenuItems(menu.find((m) => m.type === MenuType.Gallery)!.children!);
        break;
    }
    setMenuOpen(true);
  }

  React.useEffect(() => {
    getAllDocs(Collection.Menu).then((docs) => {
      setMenu((current) => {
        const updated = [...current];
        let menuDocs = docs as MenuItems[];
        menuDocs.forEach((m) => {
          if (m.id === 'information') {
            const infoMenuItem = updated.find((m) => m.type === MenuType.Information);
            const children = m.items;
            children.forEach((c, i) => {
              c.type = 1000 + i;
            });
            infoMenuItem!.children!.push(...m.items);
          }
        });
        return updated;
      });
    });
  }, []);

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (menuAnchorEl && menuAnchorEl.contains(event.target as HTMLElement)) {
      return;
    }
    setMenuOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setMenuOpen(false);
    }
  }

  return (
    <React.Fragment>
      <SideMenu menu={menu} open={drawerOpen} onClose={() => setDrawerOpen(false)} />
      <Grid className={classes.root}>
        <Container maxWidth="lg" className={classes.container}>
          <Hidden mdUp>
            <MenuIconButton onClick={() => setDrawerOpen((opened) => !opened)}>
              <Icon.Menu />
            </MenuIconButton>
          </Hidden>
          <Hidden smDown>
            {menu.map((m) => {
              if (m.path) {
                return (
                  <Button key={m.type} className={classes.button} onClick={() => navigate(m.path!)}>
                    {m.title}
                  </Button>
                );
              }
              return (
                <Button
                  key={m.type}
                  className={classes.button}
                  onClick={() => openSubMenu(m.type)}
                  ref={(r) => buttonRefs.current.push({ type: m.type, ref: r })}
                >
                  {m.title}
                </Button>
              );
            })}
            <Popper className={classes.menuPopper} open={menuOpen} anchorEl={menuAnchorEl} transition disablePortal>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList autoFocusItem={menuOpen} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                        {menuItems.map((menuItem) => (
                          <MUIMenuItem
                            key={menuItem.path}
                            onClick={() => navigate(menuItem.path!)}
                            className={classes.menuItem}
                          >
                            <Typography variant="inherit">{menuItem.title}</Typography>
                          </MUIMenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Hidden>
        </Container>
      </Grid>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  button: {
    borderRadius: 0,
    padding: theme.spacing(2.5),
    color: theme.palette.primary.contrastText,
  },
  menuPopper: {
    zIndex: 1201,
  },
  menuItem: {
    minWidth: 150,
    justifyContent: 'center',
  },
}));

export default Menu;
