import * as React from 'react';
import styled from 'styled-components';
import {
  GridList as MuiGridList,
  GridListTile as MuiGridListTile,
  GridListTileBar as MuiGridListTileBar,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import { Gallery } from '../types';

const GridList = styled(MuiGridList)``;

const GridListTile = styled(MuiGridListTile)`
  cursor: pointer;
  &:hover {
    opacity: 0.75;
  }
`;

const GridListTileBar = styled(MuiGridListTileBar)``;

type Props = {
  galleries: Gallery[];
  onClick: (gallery: Gallery) => void;
};

export const GalleryList: React.FC<Props> = (props) => {
  const { galleries, onClick } = props;
  const theme = useTheme();
  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <GridList cellHeight={200} cols={isXsDown ? 2 : 4}>
      {galleries.map((g) => (
        <GridListTile key={g.id} onClick={() => onClick(g)}>
          {g.images && g.images.length > 0 && <img src={g.images[0].url} role="presentation" />}
          <GridListTileBar title={g.name} />
        </GridListTile>
      ))}
    </GridList>
  );
};
