import * as React from 'react';
import styled from 'styled-components';
import { List, ListItem, ListItemIcon, ListItemText, Drawer as MUIDrawer } from '@material-ui/core';
import * as MDIcon from 'mdi-material-ui';
import { useHistory } from 'react-router';

// #region styles

export const drawerWidth = 250;

const Logo = styled.img`
  margin: 0 auto;
  height: 45px;
`;

const DrawerStyled = styled(MUIDrawer)`
  .MuiDrawer-paper {
    position: relative;
    white-space: nowrap;
    width: ${drawerWidth}px;
  }
`;

const DrawerList = styled(List)`
  padding-top: 0;
`;

const HeaderListItem = styled(ListItem)`
  ${({ theme }) => `
    min-height: 56px;
    ${[theme.breakpoints.up('xs')]} and (orientation: landscape)] {
      min-height: 48px;
    }
    ${[theme.breakpoints.up('sm')]} {
      min-height: 64px;
    }
  `}
` as typeof ListItem;

// #endregion

const Drawer: React.FC = () => {
  const history = useHistory();

  const navigate = (path: string) => {
    history.push(path);
  };

  return (
    <DrawerStyled variant="permanent" open>
      <DrawerList>
        <HeaderListItem divider>
          <Logo src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="Finesse Architectural Coatings" />
        </HeaderListItem>
        <ListItem button onClick={() => navigate('/admin/home')}>
          <ListItemIcon>
            <MDIcon.Home />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem button onClick={() => navigate('/admin/pages')}>
          <ListItemIcon>
            <MDIcon.FileDocumentMultiple />
          </ListItemIcon>
          <ListItemText primary="Pages" />
        </ListItem>
        <ListItem button onClick={() => navigate('/admin/manufactures')}>
          <ListItemIcon>
            <MDIcon.Factory />
          </ListItemIcon>
          <ListItemText primary="Manufactures" />
        </ListItem>
        <ListItem button onClick={() => navigate('/admin/products')}>
          <ListItemIcon>
            <MDIcon.TagMultiple />
          </ListItemIcon>
          <ListItemText primary="Products" />
        </ListItem>
        <ListItem button onClick={() => navigate('/admin/galleries')}>
          <ListItemIcon>
            <MDIcon.Image />
          </ListItemIcon>
          <ListItemText primary="Galleries" />
        </ListItem>
        <ListItem button onClick={() => navigate('/admin/videos')}>
          <ListItemIcon>
            <MDIcon.Video />
          </ListItemIcon>
          <ListItemText primary="Videos" />
        </ListItem>
        <ListItem button onClick={() => navigate('/admin/awards')}>
          <ListItemIcon>
            <MDIcon.Seal />
          </ListItemIcon>
          <ListItemText primary="Awards" />
        </ListItem>
        <ListItem button onClick={() => navigate('/admin/documents')}>
          <ListItemIcon>
            <MDIcon.File />
          </ListItemIcon>
          <ListItemText primary="Documents" />
        </ListItem>
      </DrawerList>
    </DrawerStyled>
  );
};

export default Drawer;
