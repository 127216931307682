import React from 'react';
import clsx from 'clsx';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { Avatar, Card, CardHeader, makeStyles } from '@material-ui/core';
import { blueGrey } from '@material-ui/core/colors';
import { orderDocs } from '../../firebase/firestore';
import { capitalize } from '../shared/utils';
import { SetMessageContext } from '../../context';
import { Collection, MessageType, OrderedNamedDoc } from '../../types';
import BaseDialog from './BaseDialog';

type Props = {
  title: string;
  open: boolean;
  onClose: () => void;
  items: OrderedNamedDoc[];
  icon: JSX.Element;
  collection: Collection;
};

const OrderDialog: React.FC<Props> = (props) => {
  const { items: defaultItems } = props;
  const classes = useStyles();
  const setMessage = React.useContext(SetMessageContext);
  const [busy, setBusy] = React.useState(false);
  const [items, setItems] = React.useState<OrderedNamedDoc[]>([]);
  const itemsRef = React.useRef<OrderedNamedDoc[]>([]);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    let prevIndex = result.source.index;
    let index = result.destination.index;
    setItems((state) => {
      let ordered = [...state];
      if (index >= ordered.length) {
        var k = index - ordered.length + 1;
        while (k--) {
          ordered.push(undefined as any);
        }
      }
      ordered.splice(index, 0, ordered.splice(prevIndex, 1)[0]);
      var order = 0;
      ordered.forEach((o) => {
        o.order = order++;
      });
      return ordered;
    });
  };

  const onCancel = () => {
    props.onClose();
    setItems(itemsRef.current);
  };

  const onUpdate = () => {
    setBusy(true);
    orderDocs(props.collection, items)
      .then(() => {
        props.onClose();
        setMessage({ type: MessageType.Success, text: `${capitalize(props.collection)} ordered successfully!` });
        itemsRef.current = items;
        setBusy(false);
      })
      .catch(() => {
        setBusy(false);
        setMessage({
          type: MessageType.Error,
          text: `Failed to order ${props.collection}, please refresh the page and try again!`,
        });
      });
  };

  React.useEffect(() => {
    itemsRef.current = defaultItems.sort((a, b) => a.order - b.order);
    setItems(itemsRef.current);
  }, [defaultItems]);

  return (
    <BaseDialog
      title={props.title}
      open={props.open}
      busy={busy}
      onClose={onCancel}
      onOK={onUpdate}
      dialogContentProps={{
        className: classes.dialogContent,
      }}
      fullWidth={false}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={clsx(classes.listStyle, snapshot.isDraggingOver ? classes.listStyleDragging : {})}
            >
              {items.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id!} index={index}>
                  {(provided, snapshot) => (
                    <Card
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={provided.draggableProps.style}
                      className={classes.itemStyle}
                    >
                      <CardHeader avatar={<Avatar className={classes.avatar}>{props.icon}</Avatar>} title={item.name} />
                    </Card>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </BaseDialog>
  );
};

const useStyles = makeStyles((theme) => ({
  avatar: {},
  dialogContent: {
    padding: 0,
    width: 400,
  },
  listStyle: {
    backgroundColor: theme.palette.background.default,
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  listStyleDragging: {
    backgroundColor: blueGrey[50],
  },
  itemStyle: {
    userSelect: 'none',
    margin: `0 0 ${theme.spacing(1)}px 0`,
  },
}));

export default OrderDialog;
