import React from 'react';
import styled from 'styled-components';
import {
  Button,
  Backdrop,
  Card as MuiCard,
  CardHeader,
  CardContent,
  Container,
  Divider,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Upload as UploadIcon } from 'mdi-material-ui';
import { useRouteMatch, useHistory } from 'react-router';
import slugify from 'slugify';
import { Upload } from '../shared/FileUpload';
import { getNamedDoc, setDoc } from '../../firebase/firestore';
import { SetTitleContext } from '../shared/Layout';
import { useForm } from './form';
import { Collection, Award, MessageType } from '../../types';
import UploadDialog from '../shared/UploadDialog';
import AwardForm from './AwardForm';
import ImageTile from '../shared/ImageTile';
import { SetMessageContext } from '../../context';
import { generateURL } from '../../helpers/url';

const imagePath = 'public/images/awards/';

const Card = styled(MuiCard)`
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

const EditAward: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const form = useForm();
  const [image, setImage] = React.useState<string | null>(null);
  const [imageBackdropOpen, setImageBackdropOpen] = React.useState(false);
  const [busy, setBusy] = React.useState(false);
  const [uploadDialogOpen, setUploadDialogOpen] = React.useState(false);
  const setMessage = React.useContext(SetMessageContext);
  const setTitle = React.useContext(SetTitleContext);
  const awardRef = React.useRef<Award>();
  const routeMatch = useRouteMatch<{ slug: string }>();
  const { slug } = routeMatch.params;

  const saveChanges = () => {
    if (!form.validate()) {
      return;
    }
    setBusy(true);
    let slug = slugify(form.name, { lower: true });
    let award: Award = {
      ...awardRef.current!,
      name: form.name,
      slug: slug,
      description: form.description,
    };
    return setDoc(Collection.Awards, award)
      .then(() => {
        history.replace(`/admin/awards/${slug}`);
        awardRef.current = award;
        setMessage({ type: MessageType.Success, text: 'Award updated successfully!' });
      })
      .catch(() => {
        setMessage({ type: MessageType.Error, text: 'Failed to update award, please refresh the page and try again!' });
      })
      .finally(() => {
        setBusy(false);
      });
  };

  const cancelChanges = () => {
    form.reset();
  };

  const onDeleteImage = () => {
    const award = awardRef.current;
    if (!award?.filename) {
      return;
    }
    delete award.filename;
    setDoc(Collection.Awards, award, false)
      .then(() => {
        setMessage({ type: MessageType.Success, text: 'Image deleted successfully!' });
        setImage(null);
      })
      .catch(() => {
        setMessage({ type: MessageType.Error, text: 'Failed to delete image, please refresh the page and try again!' });
      });
  };

  const onUpload = (uploads: Upload[]) => {
    uploads.forEach((upload) => {
      const ext = upload.file.name.split('.').pop();
      upload.filename = `${awardRef.current!.id}.${ext}`;
    });
  };

  const onUploadCompleted = (upload: Upload) => {
    const award: Award = { ...awardRef.current!, filename: upload.filename! };
    setDoc(Collection.Awards, award).then(() => {
      awardRef.current = award;
      setImage(award.filename ? generateURL(`${imagePath}${award.filename}`) : null);
    });
  };

  React.useEffect(() => {
    getNamedDoc(Collection.Awards, slug)
      .then((doc) => {
        const award = doc as Award;
        awardRef.current = award;
        form.set(award);
        setImage(award.filename ? generateURL(`${imagePath}${award.filename}`) : null);
        setTitle(award.name);
      })
      .catch((err) => {
        console.log(err);
        setMessage({ type: MessageType.Error, text: 'Failed to load award, please reload the page and try again.' });
      });
  }, [slug]);

  if (!awardRef.current) {
    return null;
  }

  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <Card square>
          <CardContent>
            <Grid container spacing={2}>
              <AwardForm form={form} busy={busy} />
              <Grid item xs={12} className={classes.buttonsItem}>
                <Button
                  variant="contained"
                  disableElevation
                  className={classes.cancelButton}
                  onClick={cancelChanges}
                  disabled={busy || !form.dirty}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  disableElevation
                  color="primary"
                  onClick={saveChanges}
                  disabled={busy || !form.dirty}
                >
                  Save Changes
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card square>
          <CardHeader title="Award Image" />
          <Divider />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} className={classes.imageItem}>
                {image && (
                  <ImageTile
                    // name={image.name}
                    url={image}
                    onView={() => setImageBackdropOpen(true)}
                    onDelete={onDeleteImage}
                  />
                )}
                {!image && (
                  <Button
                    variant="outlined"
                    onClick={() => setUploadDialogOpen(true)}
                    disabled={busy}
                    startIcon={<UploadIcon />}
                  >
                    Upload Award Image
                  </Button>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
      <UploadDialog
        title="Upload Award Image"
        dragText="Drag Image Here"
        path={imagePath}
        open={uploadDialogOpen}
        onClose={() => setUploadDialogOpen(false)}
        onUpload={onUpload}
        onUploadCompleted={onUploadCompleted}
        accept="
					image/png, 
					image/jpeg, 
					image/gif, 
					image/bmp, 
					image/tiff, 
					image/webp, 
					image/svg+xml"
      />
      <Backdrop className={classes.imageBackdrop} open={imageBackdropOpen} onClick={() => setImageBackdropOpen(false)}>
        {image && <img className={classes.imageFullScreen} src={image} alt="Award" />}
      </Backdrop>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  buttonsItem: {
    textAlign: 'right',
  },
  cancelButton: {
    marginRight: theme.spacing(2),
  },
  imageItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageBackdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    padding: theme.spacing(4),
  },
  imageFullScreen: {
    padding: 0,
    display: 'block',
    margin: '0 auto',
    maxHeight: '100%',
    maxWidth: '100%',
  },
}));

export default EditAward;
