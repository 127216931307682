import React from 'react';
import {
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select as MUISelect
} from '@material-ui/core';

// type Props = {
// 	helperText?: string | null, 
// 	label: string,
// 	onChange?: (value: any) => void,
// 	value: string | number | null,
// 	values: { value: string | number, text: string }[],
// 	//disabled?: boolean
// } & FormControlProps;

type Props = {
	helperText?: string | null, 
	label: string,
	onChange?: (value: any) => void,
	value: string | number | null,
	values: { value: string | number, text: string }[],
	disabled?: boolean
} & any;

const Select: React.FC<Props> = (props) => {
	const { helperText, label, onChange, value, values, ...formProps } = props;
	const inputLabel = React.useRef<HTMLLabelElement | null>(null);
	const [labelWidth, setLabelWidth] = React.useState(0);
	
	function onSelectChange(e: React.ChangeEvent<{ value: unknown }>) {
		if (onChange) {
			onChange(e.target.value);
		}
  }

	React.useEffect(() => {
    if (!inputLabel.current) {
      return;
    }
    setLabelWidth(inputLabel.current!.offsetWidth);
  }, []);

	return (
		<FormControl variant="outlined" {...formProps}>
			<InputLabel id="page-label" ref={ref => inputLabel.current = ref}>
				{label}
			</InputLabel>
			<MUISelect
				labelId="page-label"
				value={value}
				labelWidth={labelWidth}
				onChange={onSelectChange}
			>
				{values.map((tvp: { value: string | number, text: string }) => (
					<MenuItem key={tvp.value} value={tvp.value}>{tvp.text}</MenuItem>
				))}
			</MUISelect>
			{helperText && <FormHelperText>{helperText}</FormHelperText>}
		</FormControl>
	);
}



export default Select;