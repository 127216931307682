import * as React from 'react';
import styled from 'styled-components';
import { Button as MuiButton, Grid, TextField } from '@material-ui/core';
import { Link as LinkIcon, LinkOff as LinkOffIcon } from 'mdi-material-ui';
import { ContentState, DraftDecorator, EntityInstance, EditorState, ContentBlock } from 'draft-js';

import BaseDialog from '../BaseDialog';

const Button = styled(MuiButton)`
  padding: 11px;
  min-width: 0;
  color: rgba(0, 0, 0, 0.38);
`;

type Props = {
  editorState: EditorState;
  onLink: (url: string | null) => void;
  onOpening: () => void;
  onClosing: () => void;
};

export const LinkControl: React.FC<Props> = (props) => {
  const { editorState, onLink, onOpening, onClosing } = props;
  const [open, setOpen] = React.useState(false);
  const [url, setURL] = React.useState('');

  const selection = editorState.getSelection();
  const startKey = selection.getStartKey();
  const startOffset = selection.getStartOffset();
  console.log(selection);
  console.log(`empty? ${selection.isEmpty()}`);
  console.log(`collapsed? ${selection.isCollapsed()}`);
  const contentState = editorState.getCurrentContent();
  const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
  const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
  let linkEntity: EntityInstance | null = null;
  if (linkKey) {
    const entity = contentState.getEntity(linkKey);
    if (entity.getType() === 'LINK') {
      linkEntity = entity;
    }
  }

  const onClick = () => {
    if (linkEntity) {
      onLink(null);
    } else {
      if (selection.isCollapsed()) {
        alert('Select the text that you want to convert to a link.');
        return;
      }
      onOpening();
      setOpen(true);
    }
  };

  const onOK = () => {
    onLink(url);
    onClosing();
    setOpen(false);
    setURL('');
  };

  const onClose = () => {
    onClosing();
    setOpen(false);
    setURL('');
  };

  return (
    <>
      <Button onClick={onClick} title={linkEntity ? 'Remove Link' : 'Convert Selected Text To Link'}>
        {!linkEntity && <LinkIcon />}
        {linkEntity && <LinkOffIcon />}
      </Button>
      <BaseDialog title="Add Link" open={open} onOK={onOK} onClose={onClose}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="dense"
              fullWidth
              label="URL"
              value={url}
              onChange={(e) => setURL(e.target.value)}
            />
          </Grid>
        </Grid>
      </BaseDialog>
    </>
  );
};

const findLinkEntities = (
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState
) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
  }, callback);
};

type LinkProps = {
  contentState: ContentState;
  entityKey: string;
};

const Link: React.FC<LinkProps> = (props) => {
  const { contentState, entityKey } = props;
  const { url } = contentState.getEntity(entityKey).getData();
  return <a href={url}>{props.children}</a>;
};

export const linkDecorator: DraftDecorator = {
  strategy: findLinkEntities,
  component: Link,
};
