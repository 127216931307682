import { EditorState, Modifier } from 'draft-js';

export const getSelectedBlocksMap = (editorState: EditorState) => {
  const selectionState = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  const startKey = selectionState.getStartKey();
  const endKey = selectionState.getEndKey();
  const blockMap = contentState.getBlockMap();
  return blockMap
    .toSeq()
    .skipUntil((_, k) => k === startKey)
    .takeUntil((_, k) => k === endKey)
    .concat([[endKey, blockMap.get(endKey)]]);
};

/**
 * Function returns collection of currently selected blocks.
 */
export function getSelectedBlocksList(editorState: EditorState) {
  return getSelectedBlocksMap(editorState).toList();
}

/**
 * Function returns the first selected block.
 */
export function getSelectedBlock(editorState: EditorState) {
  if (editorState) {
    return getSelectedBlocksList(editorState).get(0);
  }
  return undefined;
}

/**
 * Function will add block level meta-data.
 */
export const setBlockData = (editorState: EditorState, data: any) => {
  const newContentState = Modifier.setBlockData(editorState.getCurrentContent(), editorState.getSelection(), data);
  return EditorState.push(editorState, newContentState, 'change-block-data');
};
