import * as React from 'react';
import 'firebase/auth';
import styled from 'styled-components';
import app from 'firebase/app';
import {
  AppBar as MuiAppBar,
  Avatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Container as MuiContainer,
  CssBaseline,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  Account as AccountIcon,
  ArrowLeft as BackIcon,
  Lock as LockIcon,
  LogoutVariant as LogoutIcon,
} from 'mdi-material-ui';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import useGlobalStyles from './globalStyles';
import { capitalize } from './utils';
import { UserContext } from '../../context';
import { LayoutProps } from '../../types';
import Drawer from './Drawer';
import Snackbar from '../../shared/Snackbar';

// #region styles

const RootContainer = styled.div`
  display: flex;
`;

const Main = styled.main`
  flex-grow: 1;
  height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const AppBar = styled(MuiAppBar)`
  flex: 0;
  z-index: ${({ theme }) => theme.zIndex.drawer + 1};
`;

const BackButton = styled(IconButton)`
  ${({ theme }) => `
    color: ${theme.palette.primary.contrastText};
    margin-right: ${theme.spacing(1)}px;
  `}
`;

const Breadcrumbs = styled(MuiBreadcrumbs)`
  color: ${({ theme }) => theme.palette.primary.contrastText};
`;

const Link = styled(RouterLink)`
  color: ${({ theme }) => theme.palette.primary.contrastText};
`;

const AppBarRightBox = styled(Box)`
  margin-left: auto;
`;

const Container = styled(MuiContainer)`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${({ theme }) => theme.spacing(4, 4, 4, 4)};
`;

// #endregion

export const SetTitleContext = React.createContext<React.Dispatch<React.SetStateAction<string>>>(undefined as any);

type Breadcrumb = { label: string; path?: string };

export const Layout: React.FC<LayoutProps> = (props) => {
  useGlobalStyles();
  const { children } = props;
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch<{ slug?: string }>();
  const user = React.useContext(UserContext);
  const [breadcrumbs, setBreadcrumbs] = React.useState<Breadcrumb[]>([]);
  const [title, setTitle] = React.useState<string>('');
  const [accountMenuOpen, setAccountMenuOpen] = React.useState(false);
  const accountButtonRef = React.useRef<Element | null>(null);

  function onBack(breadcrumb?: Breadcrumb) {
    if (breadcrumb && breadcrumb.path) {
      history.replace(breadcrumb.path);
    }
  }

  function onAccountSettings() {
    const win = window.open('https://myaccount.google.com/security', '_blank');
    if (win) {
      win.focus();
    }
  }

  function onLogout() {
    app.auth().signOut();
  }

  React.useEffect(() => {
    const pathNames = location.pathname.split('/').filter((x) => x && x !== 'admin');
    setBreadcrumbs(
      pathNames.map((pathName, index) => {
        const current = index === pathNames.length - 1;
        let label = '';
        if (!current || !match.params.slug) {
          label = capitalize(pathName);
        } else if (current && title) {
          label = title;
        }
        let path;
        if (!current) {
          path = `/admin/${pathNames.slice(0, index + 1).join('/')}`;
        }
        return { label, path };
      })
    );
  }, [location, match, title]);

  const backCrumb = breadcrumbs.length > 1 ? breadcrumbs[breadcrumbs.length - 2] : undefined;
  return (
    <SetTitleContext.Provider value={setTitle}>
      <RootContainer>
        <CssBaseline />
        <Drawer />
        <Main>
          <AppBar position="relative">
            <Toolbar>
              {backCrumb && (
                <Tooltip title={backCrumb.label}>
                  <BackButton onClick={() => onBack(backCrumb)}>
                    <BackIcon />
                  </BackButton>
                </Tooltip>
              )}
              <Breadcrumbs>
                {breadcrumbs.map((breadcrumb, index) => {
                  const key = index;
                  return breadcrumb.path ? (
                    <Link key={key} to={breadcrumb.path}>
                      {breadcrumb.label}
                    </Link>
                  ) : (
                    <Typography key={key}>{breadcrumb.label}</Typography>
                  );
                })}
              </Breadcrumbs>
              <AppBarRightBox>
                {user != null && (
                  <>
                    <IconButton
                      ref={(ref) => {
                        accountButtonRef.current = ref;
                      }}
                      onClick={() => setAccountMenuOpen(true)}
                    >
                      <Avatar alt={user.displayName ? user.displayName : ''} src={user.photoURL ? user.photoURL : ''}>
                        <AccountIcon />
                      </Avatar>
                    </IconButton>
                    <Menu
                      open={accountMenuOpen}
                      onClose={() => setAccountMenuOpen(false)}
                      anchorEl={accountButtonRef.current}
                      getContentAnchorEl={null}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                      PaperProps={{
                        style: { borderRadius: 0 },
                      }}
                    >
                      <MenuItem onClick={onAccountSettings}>
                        <ListItemIcon>
                          <LockIcon />
                        </ListItemIcon>
                        <Typography variant="inherit">Account Security</Typography>
                      </MenuItem>
                      <MenuItem onClick={onLogout}>
                        <ListItemIcon>
                          <LogoutIcon />
                        </ListItemIcon>
                        <Typography variant="inherit">Logout</Typography>
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </AppBarRightBox>
            </Toolbar>
          </AppBar>
          <Container maxWidth="xl">{children as any}</Container>
        </Main>
        <Snackbar />
      </RootContainer>
    </SetTitleContext.Provider>
  );
};
