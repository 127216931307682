import * as React from 'react';
import { Button, Grid, makeStyles, useTheme } from '@material-ui/core';
import { Image as ImageIcon, Pencil as EditIcon, Delete as DeleteIcon } from 'mdi-material-ui';
import MaterialTable from 'material-table';
import { useHistory } from 'react-router';
import { observeCollection, deleteDoc } from '../../firebase/firestore';
import { Collection, MessageType, OrderedNamedDoc } from '../../types';
import { SetMessageContext } from '../../context';
import CreateGalleryDialog from './CreateGalleryDialog';
import OrderDialog from '../shared/OrderDialog';

const Galleries: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const setMessage = React.useContext(SetMessageContext);
  const [createDialogOpen, setCreateDialogOpen] = React.useState(false);
  const [orderDialogOpen, setOrderDialogOpen] = React.useState(false);
  const [galleries, setGalleries] = React.useState<OrderedNamedDoc[]>([]);
  const history = useHistory();

  const editGallery = (gallery: OrderedNamedDoc) => {
    history.push(`/admin/galleries/${gallery.slug}`);
  };

  const deleteGallery = (gallery: OrderedNamedDoc) => {
    if (!window.confirm("Are you sure you want to delete this gallery and all of it's images?")) {
      return;
    }
    deleteDoc(Collection.Galleries, gallery.id!)
      .then(() => {
        setMessage({ type: MessageType.Success, text: 'Gallery deleted successfully!' });
      })
      .catch(() => {
        setMessage({
          type: MessageType.Success,
          text: 'Delete gallery failed, please refresh the page and try again!',
        });
      });
  };

  React.useEffect(() => {
    observeCollection(Collection.Galleries, false, (g) => {
      console.log(g);
      setGalleries(g as OrderedNamedDoc[]);
    });
  }, []);

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            disableElevation
            color="primary"
            className={classes.button}
            onClick={() => setCreateDialogOpen(true)}
          >
            Create Gallery
          </Button>
          <Button
            variant="contained"
            disableElevation
            className={classes.button}
            onClick={() => setOrderDialogOpen(true)}
          >
            Order Galleries
          </Button>
        </Grid>
        <Grid item xs={12}>
          <MaterialTable
            title="Galleries"
            style={{ borderRadius: 0 }}
            columns={[
              { title: 'Name', field: 'name' },
              { hidden: true, field: 'order', sorting: true, defaultSort: 'asc' },
            ]}
            data={galleries}
            options={{
              headerStyle: {
                paddingLeft: theme.spacing(2),
              },
              pageSize: 10,
              pageSizeOptions: [10, 20, 50],
            }}
            localization={{
              body: {
                emptyDataSourceMessage: 'No galleries to display',
              },
            }}
            actions={[
              {
                icon: () => <EditIcon />,
                tooltip: 'Edit Gallery',
                onClick: (_, gallery) => editGallery(gallery as OrderedNamedDoc),
              },
              {
                icon: () => <DeleteIcon />,
                tooltip: 'Delete Gallery',
                onClick: (_, gallery) => deleteGallery(gallery as OrderedNamedDoc),
              },
            ]}
          />
        </Grid>
      </Grid>
      <CreateGalleryDialog open={createDialogOpen} onClose={() => setCreateDialogOpen(false)} onCreated={editGallery} />
      <OrderDialog
        title="Order Galleries"
        open={orderDialogOpen}
        onClose={() => setOrderDialogOpen(false)}
        collection={Collection.Galleries}
        items={galleries}
        icon={<ImageIcon />}
      />
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));

export default Galleries;
