import * as React from 'react';
import { Grid, Button, useTheme } from '@material-ui/core';
import { Pencil as PencilIcon, Delete as DeleteIcon, Factory as ManufactureIcon } from 'mdi-material-ui';
import MaterialTable from 'material-table';
import { useHistory } from 'react-router';
import { containsProducts, deleteDoc, getAllDocs } from '../../firebase/firestore';
import { SetMessageContext } from '../../context';
import { Manufacture, Collection, MessageType } from '../../types';
import CreateDialog from './CreateManufactureDialog';
import OrderDialog from '../shared/OrderDialog';

const Manufactures: React.FC = () => {
  const theme = useTheme();
  const history = useHistory();
  const setMessage = React.useContext(SetMessageContext);
  const [createDialogOpen, setCreateDialogOpen] = React.useState(false);
  const [orderDialogOpen, setOrderDialogOpen] = React.useState(false);
  const [manufactures, setManufactures] = React.useState<Manufacture[]>([]);

  const editManufacture = (manufacture: Manufacture) => {
    history.push(`/admin/manufactures/${manufacture.slug}`);
  };

  const deleteManufacture = (manufacture: Manufacture) => {
    if (!window.confirm('Are you sure you want to delete this manufacture?')) {
      return;
    }
    // TODO: Check that no products are associated to this manufacture.
    let containsMsg =
      'Manufacture has products associated with it. All products must be unassociated from this manufacture before it can be deleted.';
    containsProducts(manufacture.id!)
      .then((contains) => (contains ? Promise.reject(containsMsg) : Promise.resolve()))
      .then(() => deleteDoc(Collection.Manufactures, manufacture.id!))
      .then(() => {
        setManufactures((current) => [...current.filter((m) => m.id !== manufacture.id)]);
        setMessage({ type: MessageType.Success, text: 'Manufacture deleted successfully!' });
      })
      .catch((msg) => {
        setMessage({
          type: MessageType.Error,
          text: msg ? msg : 'Delete manufacture failed, please refresh the page and try again!',
          duration: 30000,
        });
      });
  };

  React.useEffect(() => {
    getAllDocs(Collection.Manufactures).then((docs) => {
      setManufactures(docs as Manufacture[]);
    });
  }, []);

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            disableElevation
            color="primary"
            style={{ marginRight: theme.spacing(2) }}
            onClick={() => setCreateDialogOpen(true)}
          >
            Create Manufacture
          </Button>
          <Button variant="contained" disableElevation onClick={() => setOrderDialogOpen(true)}>
            Order Manufactures
          </Button>
        </Grid>
        <Grid item xs={12}>
          <MaterialTable
            title="Manufactures"
            style={{ borderRadius: 0 }}
            columns={[
              { title: 'Name', field: 'name' },
              { hidden: true, field: 'order', sorting: true, defaultSort: 'asc' },
            ]}
            data={manufactures}
            options={{
              headerStyle: {
                paddingLeft: theme.spacing(2),
              },
              pageSize: 10,
              pageSizeOptions: [10, 20, 50],
            }}
            localization={{
              body: {
                emptyDataSourceMessage: 'No manufactures to display',
              },
            }}
            actions={[
              {
                icon: () => <PencilIcon />,
                tooltip: 'Edit Manufacture',
                onClick: (_, manufacture) => editManufacture(manufacture as Manufacture),
              },
              {
                icon: () => <DeleteIcon />,
                tooltip: 'Delete Manufacture',
                onClick: (_, manufacture) => deleteManufacture(manufacture as Manufacture),
              },
            ]}
          />
        </Grid>
      </Grid>
      <CreateDialog
        open={createDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
        onCreated={(manufacture) => editManufacture(manufacture)}
      />
      <OrderDialog
        title="Order Manufactures"
        open={orderDialogOpen}
        onClose={() => setOrderDialogOpen(false)}
        collection={Collection.Manufactures}
        items={manufactures}
        icon={<ManufactureIcon />}
      />
    </React.Fragment>
  );
};

export default Manufactures;
