import * as React from 'react';
import styled from 'styled-components';
import {
  Backdrop,
  Button,
  Card as MuiCard,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
  TextField,
  makeStyles,
} from '@material-ui/core';
import * as Icon from 'mdi-material-ui';
import slugify from 'slugify';
import useNameForm from '../shared/nameForm';
import { useRouteMatch, useHistory } from 'react-router';
import { getNamedDoc, setDoc } from '../../firebase/firestore';
import { listAll, deleteFile } from '../../firebase/storage';
import { SetTitleContext } from '../shared/Layout';
import { SetMessageContext } from '../../context';
import { Collection, MessageType, NamedDoc, StorageRef } from '../../types';
import ImageTile from '../shared/ImageTile';
import UploadDialog from '../shared/UploadDialog';

// #region styles

const Card = styled(MuiCard)`
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

const UploadButton = styled(Button)`
  display: flex;
  margin: 0 0 0 auto;
`;

// #endregion

const EditGallery: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const setTitle = React.useContext(SetTitleContext);
  const setMessage = React.useContext(SetMessageContext);
  const [busy, setBusy] = React.useState(false);
  const [uploadDialogOpen, setUploadDialogOpen] = React.useState(false);
  const form = useNameForm();
  const [images, setImages] = React.useState<StorageRef[] | undefined>();
  const [image, setImage] = React.useState<StorageRef | null>();
  const routeMatch = useRouteMatch<{ slug: string }>();
  const slug = routeMatch.params.slug;
  const galleryRef = React.useRef<NamedDoc>();

  const onUploadDialogClosed = (imagesAdded: boolean) => {
    setUploadDialogOpen(false);
    if (imagesAdded) {
      loadImages();
    }
  };

  const loadImages = () => {
    listAll(`public/images/galleries/${galleryRef.current!.id!}`).then((imgs) => {
      setImages(imgs);
    });
  };

  const deleteImage = (imageName: string) => {
    if (!window.confirm('Are you sure you want to delete this image?')) {
      return;
    }
    deleteFile(`public/images/galleries/${galleryRef.current!.id!}/${imageName}`)
      .then(() => {
        setImages((imgs) => [...imgs!.filter((i) => i.name !== imageName)]);
        setMessage({ type: MessageType.Success, text: 'Image deleted successfully!' });
      })
      .catch(() => {
        setMessage({ type: MessageType.Error, text: 'Failed to delete image, please refresh the page and try again!' });
      });
  };

  const saveChanges = () => {
    if (!form.validate()) {
      return;
    }
    setBusy(true);
    let slug = slugify(form.name, { lower: true });
    let gallery: NamedDoc = {
      ...galleryRef.current!,
      name: form.name,
      slug: slug,
    };
    return setDoc(Collection.Galleries, gallery)
      .then(() => {
        galleryRef.current = gallery;
        history.replace(`/admin/galleries/${gallery.slug}`);
        setTitle(gallery.name);
        setMessage({ type: MessageType.Success, text: 'Gallery updated successfully!' });
      })
      .catch(() => {
        setMessage({
          type: MessageType.Error,
          text: 'Failed to update gallery, please refresh the page and try again!',
        });
      })
      .finally(() => {
        setBusy(false);
      });
  };

  const cancelChanges = () => {
    form.reset();
  };

  React.useEffect(() => {
    getNamedDoc(Collection.Galleries, slug).then((gallery) => {
      setTitle(gallery.name);
      galleryRef.current = gallery;
      form.set(gallery.name);
      loadImages();
    });
  }, [slug]);

  if (!galleryRef.current) {
    return null;
  }

  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <Card square>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField disabled={busy} {...form.nameField} />
              </Grid>
              <Grid item xs={12} className={classes.buttonsItem}>
                <Button className={classes.cancelButton} onClick={cancelChanges} disabled={busy}>
                  Cancel
                </Button>
                <Button variant="contained" disableElevation color="primary" onClick={saveChanges} disabled={busy}>
                  Save Changes
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
      <Container maxWidth="lg">
        <Card square>
          <CardHeader title="Images" />
          <Divider />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <UploadButton
                  variant="outlined"
                  onClick={() => setUploadDialogOpen(true)}
                  disabled={busy}
                  startIcon={<Icon.Publish />}
                >
                  Upload Images
                </UploadButton>
              </Grid>
              {images && images.length === 0 && (
                <Grid item xs={12} className={classes.emptyItem}>
                  <Icon.ImageMultiple className={classes.emptyIcon} />
                  <Typography variant="h6">Wow, so empty! Add some Images to your gallery!</Typography>
                </Grid>
              )}
              {images &&
                images.map((img) => (
                  <Grid item xs={12} md={6} lg={4} xl={3} key={img.name} className={classes.imageItem}>
                    <ImageTile
                      name={img.name}
                      url={img.url}
                      onView={() => setImage(img)}
                      onDelete={() => deleteImage(img.name)}
                    />
                  </Grid>
                ))}
            </Grid>
          </CardContent>
        </Card>
      </Container>
      <UploadDialog
        title="Upload Images"
        dragText="Drag Images Here"
        path={`public/images/galleries/${galleryRef.current!.id!}`}
        open={uploadDialogOpen}
        onClose={onUploadDialogClosed}
        allowClear={true}
        multiple={true}
        accept="
					image/png, 
					image/jpeg, 
					image/gif, 
					image/bmp, 
					image/tiff, 
					image/webp, 
					image/svg+xml"
      />
      <Backdrop className={classes.imageBackdrop} open={image != null} onClick={() => setImage(null)}>
        {image && <img className={classes.imageFullScreen} src={image.url} alt={image.name} />}
      </Backdrop>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  cancelButton: {
    marginRight: theme.spacing(2),
  },
  buttonsItem: {
    textAlign: 'right',
  },
  emptyItem: {
    textAlign: 'center',
    color: theme.palette.grey[400],
    marginBottom: theme.spacing(2),
  },
  emptyIcon: {
    fontSize: 48,
  },
  imagesHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  imageItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageBackdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    padding: theme.spacing(4),
  },
  imageFullScreen: {
    padding: 0,
    display: 'block',
    margin: '0 auto',
    maxHeight: '100%',
    maxWidth: '100%',
  },
}));

export default EditGallery;
