import React from 'react';
import styled from 'styled-components';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Button,
  Card as MuiCard,
  CardHeader,
  CardContent,
  Container,
  Divider,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import * as Icon from 'mdi-material-ui';
import { useRouteMatch, useHistory } from 'react-router';
import slugify from 'slugify';
import UploadDialog from '../shared/UploadDialog';
import FileIcon from '../shared/FileIcon';
import { Upload } from '../shared/FileUpload';
import { getNamedDoc, setDoc } from '../../firebase/firestore';
import { getURL } from '../../firebase/storage';
import { SetTitleContext } from '../shared/Layout';
import { SetMessageContext } from '../../context';
import { useForm, protectedPath, publicPath } from './form';
import { Collection, Document, MessageType } from '../../types';

// #region styles

const Card = styled(MuiCard)`
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

// #endregion

type DocumentFile = { filename: string; protected: boolean; size: number; url: string };

const EditDocument: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const form = useForm();
  const [busy, setBusy] = React.useState(false);
  const [uploadDialogOpen, setUploadDialogOpen] = React.useState(false);
  const [uploadDialogTitle, setUploadDialogTitle] = React.useState('');
  const [uploadPath, setUploadPath] = React.useState('');
  const [file, setFile] = React.useState<DocumentFile | null>();
  const setMessage = React.useContext(SetMessageContext);
  const setTitle = React.useContext(SetTitleContext);
  const documentRef = React.useRef<Document>();
  const routeMatch = useRouteMatch<{ slug: string }>();
  const { slug } = routeMatch.params;

  const saveChanges = () => {
    if (!form.validate()) {
      return;
    }
    setBusy(true);
    let slug = slugify(form.name, { lower: true });
    let document: Document = {
      ...documentRef.current!,
      name: form.name,
      slug: slug,
      category: form.category,
      subcategory: form.subcategory,
    };
    return setDoc(Collection.Documents, document)
      .then(() => {
        history.replace(`/admin/documents/${slug}`);
        documentRef.current = document;
        setMessage({ type: MessageType.Success, text: 'Document details updated successfully!' });
      })
      .catch((error) => {
        console.log(error);
        setMessage({
          type: MessageType.Error,
          text: 'Failed to update document, please refresh the page and try again!',
        });
      })
      .finally(() => {
        setBusy(false);
      });
  };

  const cancelChanges = () => {
    form.reset();
  };

  const uploadDocument = (isProtected: boolean) => {
    if (!documentRef.current || !documentRef.current.id) {
      return;
    }
    setUploadDialogTitle(isProtected ? 'Upload Protected Document' : 'Upload Document');
    setUploadPath(`${isProtected ? protectedPath : publicPath}/${documentRef.current.id}`);
    setUploadDialogOpen(true);
  };

  const onUploadDialogClosed = () => {
    setUploadDialogOpen(false);
  };

  const onUploadCompleted = (upload: Upload, path: string) => {
    let file = upload.file;
    getURL(path, file.name).then((url) => {
      let document: Document = {
        ...documentRef.current!,
        filename: file.name,
        size: file.size,
        protected: path.indexOf('protected') > -1,
        url: url,
      };
      setDoc(Collection.Documents, document).then(() => {
        setFile({
          filename: document.filename!,
          protected: document.protected!,
          size: document.size!,
          url,
        });
        documentRef.current = document;
      });
    });
  };

  const loadDocument = () => {
    getNamedDoc(Collection.Documents, slug)
      .then((doc) => {
        console.log(doc);
        const document = doc as Document;
        documentRef.current = document;
        form.set(document);
        setTitle(document.name);
        if (document.filename) {
          setFile({
            filename: document.filename,
            protected: document.protected!,
            size: document.size!,
            url: document.url!,
          });
        }
      })
      .catch(() => {
        setMessage({ type: MessageType.Error, text: 'Failed to load document, please reload the page and try again.' });
      });
  };

  const onDeleteFile = (file: DocumentFile) => {
    if (!window.confirm('Are you sure you want to delete this document?')) {
      return;
    }
    setBusy(true);
    setDoc(Collection.Documents, {
      id: documentRef.current!.id,
      filename: null,
      protected: null,
      size: null,
      url: null,
    })
      .then(() => {
        setFile(null);
        setMessage({ type: MessageType.Success, text: 'File deleted successfully!' });
      })
      .catch((error) => {
        console.log(error);
        setMessage({ type: MessageType.Error, text: 'Failed to delete file, please refresh the page and try again.' });
      })
      .finally(() => {
        setBusy(false);
      });
  };

  const bytesToSize = (bytes: number) => {
    var sizes = ['bytes', 'kb', 'mb', 'gb', 'tb'];
    if (bytes === 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)) as any);
    return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
  };

  React.useEffect(() => {
    loadDocument();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!documentRef.current) {
    return null;
  }

  const renderFile = (file?: DocumentFile | null) => {
    if (!file) {
      return null;
    }
    let tooltip;
    let text;
    if (file.protected) {
      tooltip =
        'This document is protected and will require a password to download. To make this document public upload it again as a public document.';
      text = 'Protected';
    } else {
      tooltip =
        'This document is public and can be download by anyone. To make this document protected upload it again as a protected document.';
      text = 'Public';
    }
    return (
      <Grid item xs={12} className={classes.documentItem}>
        <Avatar>
          <FileIcon filename={file.filename} className={classes.fileIcon} />
        </Avatar>
        <Box className={classes.filenameBox}>
          <Typography component="span" className={classes.filename}>
            {file.filename}
          </Typography>
          <Typography component="span" variant="caption">
            ({bytesToSize(file.size)})
          </Typography>
          <Tooltip title={tooltip}>
            <Box
              className={clsx(classes.filePerm, file.protected ? classes.filePermProtected : classes.filePermPublic)}
            >
              <Icon.Information className={classes.filePermInfoIcon} />
              <Typography variant="caption" component="span">
                {text}
              </Typography>
            </Box>
          </Tooltip>
        </Box>
        <Tooltip title="Download">
          <IconButton onClick={() => (window.location.href = file.url)} disabled={busy}>
            <Icon.CloudDownload />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton onClick={() => onDeleteFile(file)} disabled={busy}>
            <Icon.Delete />
          </IconButton>
        </Tooltip>
      </Grid>
    );
  };

  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <Card square>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField disabled={busy} {...form.nameField} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField disabled={busy} {...form.categoryField} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField disabled={busy} {...form.setSubcategoryField} />
              </Grid>
              <Grid item xs={12} className={classes.buttonsItem}>
                <Button
                  variant="contained"
                  disableElevation
                  className={classes.cancelButton}
                  onClick={cancelChanges}
                  disabled={busy || !form.dirty}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  disableElevation
                  color="primary"
                  onClick={saveChanges}
                  disabled={busy || !form.dirty}
                >
                  Save Changes
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card square>
          <CardHeader title="Document" />
          <Divider />
          <CardContent>
            <Grid container>
              {!file && (
                <Grid item xs={12} className={classes.uploadItem}>
                  <Button
                    className={classes.uploadButton}
                    variant="outlined"
                    onClick={() => uploadDocument(true)}
                    disabled={busy}
                    startIcon={<Icon.Lock />}
                  >
                    Upload Protected Document
                  </Button>
                  <Button
                    className={classes.uploadButton}
                    variant="outlined"
                    onClick={() => uploadDocument(false)}
                    disabled={busy}
                    startIcon={<Icon.Publish />}
                  >
                    Upload Document
                  </Button>
                </Grid>
              )}
              {renderFile(file)}
            </Grid>
          </CardContent>
        </Card>
      </Container>

      <UploadDialog
        title={uploadDialogTitle}
        dragText="Drag Document Here"
        path={uploadPath}
        docID={documentRef.current.id}
        open={uploadDialogOpen}
        onClose={onUploadDialogClosed}
        onUploadCompleted={onUploadCompleted}
        allowClear={false}
        multiple={true}
        accept="
					application/pdf, 
					application/msword, 
					application/vnd.openxmlformats-officedocument.wordprocessingml.document, 
					application/vnd.ms-excel, 
					application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, 
					text/plain, 
					text/csv, 
					application/rtf, 
          text/rtf,
          image/png, 
					image/jpeg, 
					image/gif, 
					image/bmp, 
					image/tiff, 
					image/webp, 
					image/svg+xml"
      />
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  cancelButton: {
    marginRight: theme.spacing(2),
  },
  buttonsItem: {
    textAlign: 'right',
  },
  documentHeaderItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  uploadItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  documentItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  uploadButton: {
    margin: theme.spacing(2),
  },
  filenameBox: {
    marginRight: 'auto',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  filename: {
    marginRight: theme.spacing(1),
  },
  fileIcon: {
    marginLeft: 2,
    marginBottom: 2,
  },
  filePerm: {
    display: 'flex',
    alignContent: 'center',
    cursor: 'default',
  },
  filePermProtected: {
    color: theme.palette.success.main,
  },
  filePermPublic: {
    color: theme.palette.warning.dark,
  },
  filePermInfoIcon: {
    fontSize: 16,
    marginRight: theme.spacing(0.5),
  },
}));

export default EditDocument;
