import React from 'react';
import { Grid, DialogContentText } from '@material-ui/core';
import slugify from 'slugify';
import { createOrderedDoc } from '../../firebase/firestore';
import { SetMessageContext } from '../../context';
import { useForm } from './form';
import { Collection, Award, MessageType } from '../../types';
import BaseDialog from '../shared/BaseDialog';
import AwardForm from './AwardForm';

type Props = {
  open: boolean;
  onClose: () => void;
  onCreated: (award: Award) => void;
};

const CreateAwardDialog: React.FC<Props> = (props) => {
  const { open, onClose, onCreated: onCreate } = props;
  const setMessage = React.useContext(SetMessageContext);
  const form = useForm();
  const [busy, setBusy] = React.useState(false);

  const createAward = () => {
    if (!form.validate()) {
      return;
    }
    setBusy(true);
    let slug = slugify(form.name, { lower: true });
    let award: Award = {
      name: form.name,
      slug,
      description: form.description,
      order: -1,
    };
    createOrderedDoc(Collection.Awards, award)
      .then((orderedDoc) => {
        setBusy(false);
        onCreate({ ...award, ...orderedDoc });
      })
      .catch(() => {
        setBusy(false);
        setMessage({ type: MessageType.Error, text: 'Failed to create award, please refresh the page and try again!' });
      });
  };

  return (
    <BaseDialog title="New Award" open={open} busy={busy} onClose={onClose} onOK={createAward}>
      <React.Fragment>
        <DialogContentText>Enter the information of your new award and then click OK.</DialogContentText>
        <Grid container spacing={2}>
          <AwardForm autoFocus form={form} busy={busy} />
        </Grid>
      </React.Fragment>
    </BaseDialog>
  );
};

export default CreateAwardDialog;
