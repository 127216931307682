import React from 'react';
import app from 'firebase/app';
import { Message } from './types';

export const UserContext = React.createContext<app.User | null | undefined>(undefined);

export const MessageContext = React.createContext<string | Message | undefined>(undefined);

export const SetMessageContext = React.createContext<
  React.Dispatch<React.SetStateAction<string | Message | undefined>>
>(undefined as any);
