import React from 'react';
import { Button, Backdrop, Container, Grid, Paper, TextField, makeStyles } from '@material-ui/core';
import { useRouteMatch, useHistory } from 'react-router';
import slugify from 'slugify';
import useNameForm from '../shared/nameForm';
import { Upload } from '../shared/FileUpload';
import { getNamedDoc, setDoc } from '../../firebase/firestore';
import { getURL } from '../../firebase/storage';
import { SetTitleContext } from '../shared/Layout';
import { SetMessageContext } from '../../context';
import { Collection, MessageType, Manufacture, StorageRef } from '../../types';
import UploadDialog from '../shared/UploadDialog';

const EditManufacture: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const form = useNameForm();
  const [image, setImage] = React.useState<StorageRef | null>(null);
  const [imageBackdropOpen, setImageBackdropOpen] = React.useState(false);
  const [busy, setBusy] = React.useState(false);
  const [uploadDialogOpen, setUploadDialogOpen] = React.useState(false);
  const setMessage = React.useContext(SetMessageContext);
  const setTitle = React.useContext(SetTitleContext);
  const manufactureRef = React.useRef<Manufacture>();
  const routeMatch = useRouteMatch<{ slug: string }>();
  const { slug } = routeMatch.params;

  const saveChanges = () => {
    if (!form.validate()) {
      return;
    }
    setBusy(true);
    let slug = slugify(form.name, { lower: true });
    let manufacture: Manufacture = {
      ...manufactureRef.current!,
      name: form.name,
      slug: slug,
    };
    return setDoc(Collection.Manufactures, manufacture)
      .then(() => {
        history.replace(`/admin/manufactures/${slug}`);
        manufactureRef.current = manufacture;
        setTitle(manufacture.name);
        setMessage({ type: MessageType.Success, text: 'Manufacture updated successfully!' });
      })
      .catch(() => {
        setMessage({
          type: MessageType.Error,
          text: 'Failed to update manufacture, please refresh the page and try again!',
        });
      })
      .finally(() => {
        setBusy(false);
      });
  };

  const cancelChanges = () => {
    form.reset();
  };

  function onUploadCompleted(upload: Upload, path: string) {
    let file = upload.file;
    getURL(path, file.name).then((url) => {
      let manufacture: Manufacture = {
        ...manufactureRef.current!,
        filename: file.name,
        url,
      };
      setDoc(Collection.Manufactures, manufacture).then(() => {
        manufactureRef.current = manufacture;
        setImage(manufacture.filename && manufacture.url ? { name: manufacture.filename, url: manufacture.url } : null);
      });
    });
  }

  function loadManufacture() {
    getNamedDoc(Collection.Manufactures, slug)
      .then((doc) => {
        const manufacture = doc as Manufacture;
        manufactureRef.current = manufacture;
        form.set(manufacture.name);
        setImage(manufacture.filename && manufacture.url ? { name: manufacture.filename, url: manufacture.url } : null);
        setTitle(manufacture.name);
      })
      .catch((err) => {
        console.log(err);
        setMessage({
          type: MessageType.Error,
          text: 'Failed to load manufacture, please reload the page and try again.',
        });
      });
  }

  React.useEffect(() => {
    loadManufacture();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!manufactureRef.current) {
    return null;
  }

  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <Paper className={classes.paper}>
          <Grid container spacing={2}>
            <TextField disabled={busy} {...form.nameField} />
            <Grid item xs={12} className={classes.buttonsItem}>
              <Button
                variant="contained"
                disableElevation
                className={classes.cancelButton}
                onClick={cancelChanges}
                disabled={busy || !form.dirty}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                disableElevation
                color="primary"
                onClick={saveChanges}
                disabled={busy || !form.dirty}
              >
                Save Changes
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Container>
      <UploadDialog
        title="Upload Manufacture Logo"
        dragText="Drag Image Here"
        path={`public/images/manufactures/${manufactureRef.current!.id!}`}
        open={uploadDialogOpen}
        onClose={() => setUploadDialogOpen(false)}
        onUploadCompleted={onUploadCompleted}
        accept="
					image/png, 
					image/jpeg, 
					image/gif, 
					image/bmp, 
					image/tiff, 
					image/webp, 
					image/svg+xml"
      />
      <Backdrop className={classes.imageBackdrop} open={imageBackdropOpen} onClick={() => setImageBackdropOpen(false)}>
        {image && <img className={classes.imageFullScreen} src={image.url} alt="Manufacture Logo" />}
      </Backdrop>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  buttonsItem: {
    textAlign: 'right',
  },
  cancelButton: {
    marginRight: theme.spacing(2),
  },
  imageItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageBackdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    padding: theme.spacing(4),
  },
  imageFullScreen: {
    padding: 0,
    display: 'block',
    margin: '0 auto',
    maxHeight: '100%',
    maxWidth: '100%',
  },
}));

export default EditManufacture;
