import * as React from 'react';
import styled from 'styled-components';
import { Button as MuiButton } from '@material-ui/core';
import { Image as ImageIcon } from 'mdi-material-ui';

import UploadDialog from '../UploadDialog';
import { Upload } from '../FileUpload';
import { generateURL } from '../../../helpers/url';

const Button = styled(MuiButton)`
  padding: 11px;
  min-width: 0;
  color: rgba(0, 0, 0, 0.38);
`;

type Props = {
  path: string;
  onOpening: () => void;
  onUpload: (url: string) => void;
  onClosing: () => void;
};

export const ImageControl: React.FC<Props> = (props) => {
  const { path, onClosing, onOpening, onUpload } = props;
  const [open, setOpen] = React.useState(false);

  const onUploadCompleted = (upload: Upload, path: string) => {
    const url = generateURL(`${path}/${upload.file.name}`);
    onUpload(url);
    setOpen(false);
  };

  return (
    <>
      <Button
        onClick={() => {
          onOpening();
          setOpen(true);
        }}
      >
        <ImageIcon />
      </Button>
      <UploadDialog
        title="Add Image"
        path={path}
        open={open}
        allowClear={false}
        onUploadCompleted={onUploadCompleted}
        onClose={() => {
          onClosing();
          setOpen(false);
        }}
      />
    </>
  );
};
