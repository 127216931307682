export enum Collection {
  Awards = 'awards',
  Documents = 'documents',
  Galleries = 'galleries',
  Manufactures = 'manufactures',
  Menu = 'menu',
  Pages = 'pages',
  Products = 'products',
  Videos = 'videos',
}

export enum MenuType {
  Home = 0,
  Information,
  Products,
  Awards,
  Gallery,
  Photos,
  Videos,
  Documents,
  ContactInfo,
}

export enum MessageType {
  Default = 0,
  Success,
  Warning,
  Error,
}

export enum PageType {
  Home = 'home',
  Slogan = 'slogan',
  Info1 = 'information1',
  Info2 = 'information2',
  Info3 = 'information3',
  Info4 = 'information4',
  Info5 = 'information5',
  Info6 = 'information6',
  Contact = 'contact',
}
