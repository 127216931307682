import * as React from 'react';
import styled from 'styled-components';
import { Button, Card, CardHeader, CardContent, Divider } from '@material-ui/core';
import { ArrowLeft as BackIcon } from 'mdi-material-ui';

import { Product as ProductDefinition } from '../types';
import { RenderDraft } from '../shared/RenderDraft';

// #region styles

const BackButton = styled(Button)`
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

// #endregion

type Props = {
  product: ProductDefinition;
  onBack: () => void;
};

export const Product: React.FC<Props> = (props) => {
  const { product, onBack } = props;

  return (
    <>
      <BackButton color="primary" startIcon={<BackIcon />} onClick={onBack}>
        Products
      </BackButton>
      <Card>
        <CardHeader title={product.name} subheader={product.category} />
        <Divider />
        <CardContent>
          <RenderDraft content={product.content} />
        </CardContent>
      </Card>
    </>
  );
};
