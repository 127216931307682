import 'firebase/auth';
import * as React from 'react';
import app from 'firebase/app';
import { DialogContentText, TextField } from '@material-ui/core';

import BaseDialog from '../admin/shared/BaseDialog';
import { documentsEmail } from '../constants';

type Props = {
  open: boolean;
  onClose: () => void;
};

export const PasswordDialog: React.FC<Props> = (props) => {
  const { open, onClose } = props;
  const [login, setLogin] = React.useState(false);
  const [error, setError] = React.useState('');
  const [busy, setBusy] = React.useState(false);
  const [password, setPassword] = React.useState('');

  const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError('');
    setPassword(e.target.value);
  };

  const onLogin = () => {
    if (password.trim().length === 0) {
      return;
    }
    setBusy(true);
    app
      .auth()
      .signInWithEmailAndPassword(documentsEmail, password)
      .then((result) => {
        console.log(result);
        onClose();
      })
      .catch(() => {
        setError('Invalid password');
        setPassword('');
        setBusy(false);
      });
  };

  const onCancelLogin = () => {
    setLogin(false);
    setPassword('');
    onClose();
  };

  return (
    <BaseDialog title="Document Password" maxWidth="sm" open={open} busy={busy} onClose={onCancelLogin} onOK={onLogin}>
      <DialogContentText>Enter the password for this document and then click ok.</DialogContentText>
      <TextField
        variant="outlined"
        margin="dense"
        type="password"
        fullWidth
        label="Password"
        disabled={busy}
        value={password}
        onChange={onPasswordChange}
        helperText={error}
        error={error.length > 0}
      />
    </BaseDialog>
  );
};
