import * as React from 'react';
import styled from 'styled-components';
import { Container as MuiContainer, Hidden, Typography } from '@material-ui/core';

import { RenderDraft } from './RenderDraft';
import { getDocFunc } from '../firebase/functions';
import { Collection, PageType, Page } from '../types';

const Wrapper = styled.div`
  background-color: #eeebea;
`;

const Container = styled(MuiContainer)<{ component: React.ElementType }>`
  display: flex;
  img {
    flex: 0;
  }
`;

const Slogan = styled(Typography)`
  flex: 1;
  margin: auto 50px !important;
  text-align: center;
  color: ${({ theme }) => theme.palette.grey[700]};
` as typeof Typography;

export const Jumbo = () => {
  const [slogan, setSlogan] = React.useState<Page>();

  React.useEffect(() => {
    getDocFunc<Page>(Collection.Pages, PageType.Slogan).then((doc) => {
      setSlogan(doc);
    });
  }, []);

  return (
    <Hidden smDown>
      <Wrapper>
        <Container maxWidth="lg" component="main">
          <Slogan component="div">{slogan && <RenderDraft content={slogan.content} />}</Slogan>
          <img src={`${process.env.PUBLIC_URL}/images/jumbo.jpg`} />
        </Container>
      </Wrapper>
    </Hidden>
  );
};
