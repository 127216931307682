import React from 'react';
import { Button, Container, Divider, Grid, Paper, Typography, makeStyles } from '@material-ui/core';
import slugify from 'slugify';
import { useRouteMatch, useHistory } from 'react-router';
import { getNamedDoc, setDoc } from '../../firebase/firestore';
import { cleanupImages } from '../../firebase/functions';
import { SetTitleContext } from '../shared/Layout';
import { SetMessageContext } from '../../context';
import { useForm } from './form';
import { Collection, Product, MessageType } from '../../types';
import ProductForm from './ProductForm';
import { Editor, EditorRef } from '../shared/editor/Editor';

const EditProduct: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const setTitle = React.useContext(SetTitleContext);
  const setMessage = React.useContext(SetMessageContext);
  const [busy, setBusy] = React.useState(false);
  const editor = React.useRef<EditorRef>(null);
  const form = useForm();
  const routeMatch = useRouteMatch<{ slug: string }>();
  const slug = routeMatch.params.slug;

  const saveChanges = () => {
    if (!form.validate()) {
      return;
    }
    setBusy(true);
    let product: Product = {
      id: form.id,
      name: form.name,
      slug: slugify(form.name, { lower: true }),
      manufacture: form.manufacture!,
      category: form.category,
      type: form.type,
      content: editor.current!.getJson(),
    };
    setDoc(Collection.Products, product)
      .then(() => {
        setMessage({ type: MessageType.Success, text: 'Product updated successfully!' });
      })
      .catch(() => {
        setMessage({
          type: MessageType.Error,
          text: 'Failed to updated product, please refresh the page and try again.',
        });
      })
      .finally(() => {
        setBusy(false);
      });
  };

  const cancelChanges = () => {
    if (window.confirm('Are you sure you want to cancel your changes?')) {
      form.reset();
      if (form.id) {
        cleanupImages(Collection.Products, form.id);
      }
    }
  };

  React.useEffect(() => {
    console.log(history);
    getNamedDoc(Collection.Products, slug).then((namedDoc) => {
      let product = namedDoc as Product;
      setTitle(`Edit Product - ${product.name}`);
      form.set(product);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <Paper className={classes.paper}>
          <Grid container spacing={2}>
            <ProductForm form={form} busy={busy} />
            <Grid item xs={12} className={classes.descriptionItem}>
              <Typography variant="h6">Description</Typography>
            </Grid>
            <Grid item xs={12} className="divider-item">
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Editor
                ref={editor}
                value={form.description}
                imagePath={`public/images/products/${form.id}`}
                onChange={() => form.setDirty(true)}
              />
            </Grid>
            <Grid item xs={12} className={classes.buttonsItem}>
              <Button className={classes.cancelButton} onClick={cancelChanges} disabled={busy || !form.dirty}>
                Cancel
              </Button>
              <Button
                variant="contained"
                disableElevation
                color="primary"
                onClick={saveChanges}
                disabled={busy || !form.dirty}
              >
                Save Changes
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  cancelButton: {
    marginRight: theme.spacing(2),
  },
  buttonsItem: {
    textAlign: 'right',
  },
  descriptionItem: {
    marginTop: theme.spacing(1),
  },
}));

export default EditProduct;
