import * as React from 'react';
import { Grid, Button, useTheme } from '@material-ui/core';
import { Pencil as PencilIcon, Delete as DeleteIcon } from 'mdi-material-ui';
import MaterialTable from 'material-table';
import { useHistory } from 'react-router';
import { getAllDocs, deleteDoc } from '../../firebase/firestore';
import { SetMessageContext } from '../../context';
import { ManufacturesContext } from '../../shared/ManufacturesProvider';
import { Product, Collection, MessageType } from '../../types';
import CreateProductDialog from './CreateProductDialog';

const Products: React.FC = () => {
  const theme = useTheme();
  const history = useHistory();
  const setMessage = React.useContext(SetMessageContext);
  const manufactures = React.useContext(ManufacturesContext);
  const [createDialogOpen, setCreateDialogOpen] = React.useState(false);
  const [products, setProducts] = React.useState<Product[]>([]);

  const editProduct = (product: Product) => {
    history.push(`/admin/products/${product.slug}`);
  };

  const deleteProduct = (product: Product) => {
    if (!window.confirm('Are you sure you want to delete this product?')) {
      return;
    }
    deleteDoc(Collection.Products, product.id!)
      .then(() => {
        setProducts((current) => [...current.filter((p) => p.id !== product.id)]);
        setMessage({ type: MessageType.Success, text: 'Product deleted successfully!' });
      })
      .catch(() => {
        setMessage({ type: MessageType.Error, text: 'Delete product failed, please refresh the page and try again!' });
      });
  };

  function renderManufacture(product: Product) {
    let manufacture = manufactures.find((m) => m.id === product.manufacture.id);
    return manufacture ? manufacture.name : null;
  }

  React.useEffect(() => {
    getAllDocs(Collection.Products).then((docs) => setProducts(docs as Product[]));
  }, []);

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button variant="contained" disableElevation color="primary" onClick={() => setCreateDialogOpen(true)}>
            Create Product
          </Button>
        </Grid>
        <Grid item xs={12}>
          <MaterialTable
            title="Products"
            style={{ borderRadius: 0 }}
            columns={[
              { title: 'Name', field: 'name' },
              { title: 'Manufacture', render: renderManufacture },
              { title: 'Category', field: 'category' },
              { title: 'Type', field: 'type' },
            ]}
            data={products}
            options={{
              headerStyle: {
                paddingLeft: theme.spacing(2),
              },
              pageSize: 10,
              pageSizeOptions: [10, 20, 50],
            }}
            localization={{
              body: {
                emptyDataSourceMessage: 'No products to display',
              },
            }}
            actions={[
              {
                icon: () => <PencilIcon />,
                tooltip: 'Edit Product',
                onClick: (_, product) => editProduct(product as Product),
              },
              {
                icon: () => <DeleteIcon />,
                tooltip: 'Delete Product',
                onClick: (_, product) => deleteProduct(product as Product),
              },
            ]}
          />
        </Grid>
      </Grid>
      <CreateProductDialog open={createDialogOpen} onClose={() => setCreateDialogOpen(false)} onCreated={editProduct} />
    </React.Fragment>
  );
};

export default Products;
