import * as React from 'react';
import styled from 'styled-components';

import { PageType } from '../types';
import { Page } from '../shared/Page';

const Container = styled.div`
  ${({ theme }) => `  
    display: flex;
    padding: ${theme.spacing(0, 2)};
    ${[theme.breakpoints.down('sm')]} {
      flex-direction: column;
    }
  `}
`;

const Main = styled(Page)`
  ${({ theme }) => `
    flex: 1;
    margin-right: ${theme.spacing(2)}px;
    ${[theme.breakpoints.down('sm')]} {
      margin-right: 0;
      margin-bottom: ${theme.spacing(2)}px;
    }
  `}
`;

const Side = styled.div<{ $width: string }>`
  width: ${({ $width }) => $width};
  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    align-self: center;
    width: 100%;
  }
`;

type Props = {
  type: PageType;
  sideWidth?: string;
  hideHeader?: boolean;
  icon?: React.ReactNode;
};

export const SplitPage: React.FC<Props> = (props) => {
  const { type, sideWidth, hideHeader, icon, children } = props;

  return (
    <Container>
      <Main hideHeader={hideHeader} type={type} icon={icon} />
      <Side $width={sideWidth ?? '300px'}>{children}</Side>
    </Container>
  );
};
