import 'firebase/auth';
import * as React from 'react';
import app from 'firebase/app';
import styled from 'styled-components';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card as MuiCard,
  CardActions as MuiCardActions,
  CardContent as MuiCardContent,
  CardMedia as MuiCardMedia,
  Divider,
  Grid,
  Paper as MuiPaper,
  Tabs,
  Tab,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { ChevronDown as ExpandMoreIcon, Lock as LockIcon, LockOpen, LockOpen as LockOpenIcon } from 'mdi-material-ui';

import { getURL } from '../firebase/storage';
import { getDocsFunc } from '../firebase/functions';
import { Collection, Document as DocumentDefinition } from '../types';
import { TabPanel as Panel } from '../shared/TabPanel';
import { PasswordDialog } from './PasswordDialog';
import { UserContext } from '../context';

// #region styles

const Paper = styled(MuiPaper)`
  border: 1px solid ${({ theme }) => theme.palette.grey[300]};
`;

const TabPanel = styled(Panel)`
  background-color: ${({ theme }) => theme.palette.background.default};
`;

const Card = styled(MuiCard)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const CardContent = styled(MuiCardContent)`
  flex: 1;
`;

const CardMedia = styled(MuiCardMedia)`
  flex: 0;
  height: 0;
  padding-top: 56.25%;
  background-color: ${({ theme }) => theme.palette.grey[300]};
`;

const CardActions = styled(MuiCardActions)`
  flex: 0;
`;

const ProtectedIcon = styled(LockIcon)`
  margin-left: auto !important;
  color: ${({ theme }) => theme.palette.grey[500]};
`;

const UnlockedIcon = styled(LockOpenIcon)`
  margin-left: auto !important;
  color: ${({ theme }) => theme.palette.grey[500]};
`;

// #endregion

type Categories = {
  [category: string]: SubCategories;
};

type SubCategories = {
  [subCategory: string]: DocumentDefinition[];
};

const Documents: React.FC = () => {
  const [categories, setCategories] = React.useState<Categories>();
  const [tab, setTab] = React.useState(0);
  const [expanded, setExpanded] = React.useState<number | null>(0);
  const user = React.useContext(UserContext);
  const [login, setLogin] = React.useState(false);
  const downloadDocRef = React.useRef<DocumentDefinition | null>(null);

  const onPanelChange = (panel: number) => (_: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : null);
  };

  const download = () => {
    if (!downloadDocRef.current) {
      return;
    }
    const doc = downloadDocRef.current;
    downloadDocRef.current = null;
    const storagePath = `${!doc.protected ? 'public' : 'protected'}/documents/${doc.id}/${doc.filename}`;
    getURL(storagePath)
      .then((url) => {
        const link = document.createElement('a');
        link.setAttribute('download', doc.filename!);
        link.href = url;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onDownloadDoc = (file: DocumentDefinition) => {
    downloadDocRef.current = file;
    if (file.protected === true && !user) {
      setLogin(true);
    } else {
      download();
    }
  };

  const onLoginClosed = () => {
    setLogin(false);
  };

  React.useEffect(() => {
    if (user) {
      download();
    }
  }, [user]);

  React.useEffect(() => {
    getDocsFunc<DocumentDefinition>(Collection.Documents).then((docs) => {
      const categories: Categories = {};
      docs.forEach((doc) => {
        if (!categories[doc.category]) {
          categories[doc.category] = {};
        }
        if (!categories[doc.category][doc.subcategory]) {
          categories[doc.category][doc.subcategory] = [];
        }
        categories[doc.category][doc.subcategory].push(doc);
      });
      setCategories(categories);
    });
  }, []);

  return (
    <>
      {categories && (
        <Paper square>
          <Tabs
            value={tab}
            onChange={(_, tabIndex) => setTab(tabIndex)}
            aria-label="document categories"
            indicatorColor="primary"
            textColor="primary"
          >
            {Object.keys(categories).map((category) => (
              <Tab key={category} label={category} />
            ))}
          </Tabs>
          <Divider />
          {Object.keys(categories).map((category, index) => (
            <TabPanel key={category} value={tab} index={index}>
              {Object.keys(categories[category]).map((subCategory, i) => (
                <Accordion key={subCategory} expanded={expanded === i} onChange={onPanelChange(i)} square>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">{subCategory}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      {categories[category][subCategory].map((file) => (
                        <Grid key={file.id} item xs={12} sm={6} md={3}>
                          <Card elevation={3}>
                            <CardContent>
                              <Typography variant="body2">{file.name}</Typography>
                            </CardContent>
                            <CardMedia image={`${process.env.PUBLIC_URL}/images/doc_media.png`} />
                            <CardActions>
                              <Tooltip title={`Download Document ${file.filename}`}>
                                <Button size="small" color="primary" onClick={() => onDownloadDoc(file)}>
                                  Download
                                </Button>
                              </Tooltip>
                              {file.protected && !user && (
                                <Tooltip title="Password Protected">
                                  <ProtectedIcon />
                                </Tooltip>
                              )}
                              {file.protected && user && (
                                <Tooltip title="Password Protected - Authenticated">
                                  <UnlockedIcon />
                                </Tooltip>
                              )}
                            </CardActions>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ))}
            </TabPanel>
          ))}
        </Paper>
      )}
      <PasswordDialog open={login} onClose={onLoginClosed} />
    </>
  );
};

export default Documents;
