import React from 'react';
import { Grid, DialogContentText } from '@material-ui/core';
import slugify from 'slugify';
import { setDoc } from '../../firebase/firestore';
import { useForm } from './form';
import { SetMessageContext } from '../../context';
import { Product, Collection, MessageType } from '../../types';
import BaseDialog from '../shared/BaseDialog';
import ProductForm from './ProductForm';

type Props = {
  open: boolean;
  onClose: () => void;
  onCreated: (product: Product) => void;
};

const CreateProductDialog: React.FC<Props> = (props) => {
  const { open, onClose, onCreated } = props;
  const setMessage = React.useContext(SetMessageContext);
  const form = useForm();
  const [busy, setBusy] = React.useState(false);

  const createProduct = () => {
    if (!form.validate()) {
      return;
    }
    setBusy(true);
    let product: Product = {
      name: form.name,
      slug: slugify(form.name, { lower: true }),
      manufacture: form.manufacture!,
      category: form.category,
      type: form.type,
      content: '',
    };
    setDoc(Collection.Products, product)
      .then((id) => {
        setBusy(false);
        product.id = id;
        onCreated(product);
      })
      .catch(() => {
        setBusy(false);
        setMessage({
          type: MessageType.Error,
          text: 'Failed to create product, please refresh the page and try again!',
        });
      });
  };

  return (
    <BaseDialog title="New Product" open={open} busy={busy} onClose={onClose} onOK={createProduct}>
      <React.Fragment>
        <DialogContentText>Enter the information of your new document and then click OK.</DialogContentText>
        <Grid container spacing={2}>
          <ProductForm autoFocus form={form} busy={busy} />
        </Grid>
      </React.Fragment>
    </BaseDialog>
  );
};

export default CreateProductDialog;
