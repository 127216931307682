import * as React from 'react';
import { DialogContentText, Grid, TextField } from '@material-ui/core';
import slugify from 'slugify';
import { setDoc } from '../../firebase/firestore';
import { SetMessageContext } from '../../context';
import { useForm } from './form';
import { Collection, Document, MessageType } from '../../types';
import BaseDialog from '../shared/BaseDialog';

type Props = {
  open: boolean;
  onClose: () => void;
  onCreated: (document: Document) => void;
};

const CreateDocumentDialog: React.FC<Props> = (props) => {
  const setMessage = React.useContext(SetMessageContext);
  const [busy, setBusy] = React.useState(false);
  const form = useForm();

  const onCreate = () => {
    if (!form.validate()) {
      return;
    }
    setBusy(true);
    let document: Document = {
      name: form.name,
      slug: slugify(form.name, { lower: true }),
      category: form.category,
      subcategory: form.subcategory,
      protected: false,
    };
    setDoc(Collection.Documents, document)
      .then((id) => {
        setBusy(false);
        document.id = id;
        props.onCreated(document);
      })
      .catch(() => {
        setBusy(false);
        setMessage({
          type: MessageType.Error,
          text: 'Failed to create document, please refresh the page and try again!',
        });
      });
  };

  return (
    <BaseDialog title="New Document" open={props.open} busy={busy} onClose={props.onClose} onOK={onCreate}>
      <DialogContentText>Enter the information of your new document and then click OK.</DialogContentText>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField autoFocus disabled={busy} {...form.nameField} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField disabled={busy} {...form.categoryField} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField disabled={busy} {...form.setSubcategoryField} />
        </Grid>
      </Grid>
    </BaseDialog>
  );
};

export default CreateDocumentDialog;
