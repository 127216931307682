import * as React from 'react';
import * as MDIcon from 'mdi-material-ui'
import { SvgIconProps } from '@material-ui/core';

type Props = {
	filename: string
} & SvgIconProps;

const FileIcon: React.FC<Props> = (props) => {
	var { filename, ...iconProps } = props;

	filename = filename.trim().toLowerCase();
	let Icon;
	if (filename.indexOf('.pdf') > -1) {
		Icon = MDIcon.FilePdf;
	} else if (filename.indexOf('.doc') > -1) {
		Icon = MDIcon.FileWord;
	} else if (filename.indexOf('.xls') > -1) {
		Icon = MDIcon.FileExcel;
	} else if (filename.indexOf('.csv') > -1) {
		Icon = MDIcon.FileDelimited;
	} else if (/\.png|\.jpg|\.jpeg|\.bmp|\.gif|\.svg|\.webp|\.tiff/.test(filename)) {
		Icon = MDIcon.Image;
	} else {
		Icon = MDIcon.File;
	}
	return <Icon {...iconProps} />;
}

export default FileIcon;