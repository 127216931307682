import 'firebase/storage';
import * as firebase from 'firebase/app';
import { StorageRef } from '../types';

const formatPath = (path: string, fileName?: string) => {
  path = path.trim();
  if (path.startsWith('/')) {
    path = path.slice(1);
  }
  if (!path.endsWith('/')) {
    path += '/';
  }
  if (fileName) {
    path += fileName;
  }
  return path;
};

export const uploadImage = (path: string, file: File, filename?: string): Promise<string> => {
  path = formatPath(path, filename ?? file.name);
  let storageRef = firebase.storage().ref();
  let imageRef = storageRef.child(path);
  return imageRef
    .put(file)
    .then((snapshot) => snapshot.ref.getDownloadURL())
    .then((downloadURL) => Promise.resolve(downloadURL));
};

export const listAll = (path: string): Promise<StorageRef[]> => {
  path = formatPath(path);
  let docRef = firebase.storage().ref(path);
  return docRef.listAll().then((res) => {
    let getURLRequests: Promise<StorageRef>[] = [];
    res.items.forEach((itemRef) => {
      let getURLReq = itemRef.getDownloadURL().then((url) => Promise.resolve({ name: itemRef.name, url }));
      getURLRequests.push(getURLReq);
    });
    return Promise.all(getURLRequests);
  });
};

export const getURL = (path: string, filename?: string): Promise<string> => {
  path = formatPath(path, filename);
  let docRef = firebase.storage().ref(path);
  return docRef.getDownloadURL().then((url) => Promise.resolve(url as string));
};

export const uploadFile = (
  path: string,
  file: File,
  filename?: string,
  customMetadata?: { [key: string]: string } | null,
  onProgress?: (progress: number) => void
) => {
  path = formatPath(path, filename ?? file.name);
  let imageRef = firebase.storage().ref().child(path);
  let uploadTask = imageRef.put(file, { customMetadata });
  if (onProgress) {
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
      console.log(`Progress Total: ${snapshot.totalBytes} Transferred: ${snapshot.bytesTransferred}`);
      let progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      onProgress(progress);
    });
  }
  return uploadTask;
};

export const deleteFile = (path: string, filename?: string) => {
  path = formatPath(path, filename);
  let imageRef = firebase.storage().ref().child(path);
  return imageRef.delete();
};
