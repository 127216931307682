import * as React from 'react';
import styled from 'styled-components';
import draftToHtml from 'draftjs-to-html';

// #region styles

const Wrapper = styled.div`
  img {
    text-align: center;
  }
`;

// #endregion

type Props = {
  content: string;
};

export const RenderDraft: React.FC<Props> = (props) => {
  const { content } = props;
  let markup;
  try {
    const contentState = JSON.parse(content);
    markup = draftToHtml(contentState);
  } catch (error) {
    console.log(error);
    return null;
  }
  return <Wrapper dangerouslySetInnerHTML={{ __html: markup }} />;
};
