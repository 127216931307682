import * as React from 'react';
import { Button, Grid, useTheme } from '@material-ui/core';
import { Video as VideoIcon, Pencil as EditIcon, Delete as DeleteIcon } from 'mdi-material-ui';
import MaterialTable from 'material-table';
import { observeCollection, deleteDoc } from '../../firebase/firestore';
import { Collection, MessageType, Video } from '../../types';
import { SetMessageContext } from '../../context';
import VideoDialog from './VideoDialog';
import OrderDialog from '../shared/OrderDialog';

const Videos: React.FC = () => {
  const theme = useTheme();
  const setMessage = React.useContext(SetMessageContext);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [orderDialogOpen, setOrderDialogOpen] = React.useState(false);
  const [videos, setVideos] = React.useState<Video[]>([]);
  const [currentVideo, setCurrentVideo] = React.useState<Video | null>(null);

  function createVideo() {
    setCurrentVideo(null);
    setDialogOpen(true);
  }

  function editVideo(video: Video) {
    setCurrentVideo(video);
    setDialogOpen(true);
  }

  function deleteVideo(video: Video) {
    if (!window.confirm('Are you sure you want to delete this video?')) {
      return;
    }
    deleteDoc(Collection.Videos, video.id!)
      .then(() => {
        setMessage({ type: MessageType.Success, text: 'Gallery deleted successfully!' });
      })
      .catch(() => {
        setMessage({
          type: MessageType.Success,
          text: 'Delete gallery failed, please refresh the page and try again!',
        });
      });
  }

  function onCompleted(isNew: boolean) {
    setDialogOpen(false);
    setMessage({
      type: MessageType.Success,
      text: `Video ${isNew ? 'created' : 'updated'} successfully!`,
    });
  }

  React.useEffect(() => {
    observeCollection(Collection.Videos, true, (vids) => {
      setVideos(vids as Video[]);
    });
  }, []);

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            disableElevation
            color="primary"
            style={{ marginRight: theme.spacing(2) }}
            onClick={createVideo}
          >
            Create Video
          </Button>
          <Button variant="contained" disableElevation onClick={() => setOrderDialogOpen(true)}>
            Order Videos
          </Button>
        </Grid>
        <Grid item xs={12}>
          <MaterialTable
            title="Videos"
            style={{ borderRadius: 0 }}
            columns={[
              { title: 'Name', field: 'name' },
              { hidden: true, field: 'order', sorting: true, defaultSort: 'asc' },
            ]}
            data={videos}
            options={{
              headerStyle: {
                paddingLeft: theme.spacing(2),
              },
              pageSize: 10,
              pageSizeOptions: [10, 20, 50],
            }}
            localization={{
              body: {
                emptyDataSourceMessage: 'No videos to display',
              },
            }}
            actions={[
              {
                icon: () => <EditIcon />,
                tooltip: 'Edit Video',
                onClick: (_, video) => editVideo(video as Video),
              },
              {
                icon: () => <DeleteIcon />,
                tooltip: 'Delete Video',
                onClick: (_, video) => deleteVideo(video as Video),
              },
            ]}
          />
        </Grid>
      </Grid>
      <VideoDialog
        open={dialogOpen}
        video={currentVideo}
        onClose={() => setDialogOpen(false)}
        onCompleted={onCompleted}
      />
      <OrderDialog
        title="Order Videos"
        open={orderDialogOpen}
        onClose={() => setOrderDialogOpen(false)}
        collection={Collection.Videos}
        items={videos}
        icon={<VideoIcon />}
      />
    </React.Fragment>
  );
};

export default Videos;
