import 'firebase/firestore';
import * as firebase from 'firebase/app';
import { Collection, Doc, NamedDoc, OrderedDoc, Product } from '../types';
import { useProduction } from './index';

const firestore = () => {
  let db = firebase.firestore();
  if (!useProduction && window.location.hostname === 'localhost') {
    db.settings({
      host: 'localhost:5001',
      ssl: false,
    });
  }
  return db;
};

export const observeCollection = (collection: Collection, ordered: boolean, observer: (docs: NamedDoc[]) => void) => {
  const getDocsFromQuery = (query: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>) => {
    var docs: NamedDoc[] = [];
    query.forEach((doc) => {
      docs.push({ id: doc.id, ...doc.data() } as NamedDoc);
    });
    console.log(docs);
    observer(docs);
  };

  let collectionRef = firestore().collection(collection);
  if (ordered) {
    collectionRef.orderBy('order').onSnapshot((query) => getDocsFromQuery(query));
  } else {
    collectionRef.onSnapshot((query) => getDocsFromQuery(query));
  }
};

export const getNamedDoc = (collection: Collection, slug: string): Promise<NamedDoc> => {
  return firestore()
    .collection(collection)
    .where('slug', '==', slug)
    .get()
    .then((snapshot) => {
      if (snapshot.docs.length === 0) {
        return Promise.reject('Document does not exist.');
      }
      let doc = snapshot.docs[0];
      return Promise.resolve({ id: doc.id, ref: doc.ref, ...doc.data() } as NamedDoc);
    });
};

export const getDoc = (collection: Collection, id: string): Promise<Doc> => {
  return firestore()
    .collection(collection)
    .doc(id)
    .get()
    .then((snapshot) => Promise.resolve({ id: id, ...snapshot.data() } as Doc));
};

export const getAllDocs = (collection: Collection, ordered?: boolean): Promise<Doc[]> => {
  let collectionRef = firestore().collection(collection);
  let get = ordered === true ? collectionRef.orderBy('order').get() : collectionRef.get();
  return get.then((snapshot) => Promise.resolve(snapshot.docs.map((d) => ({ id: d.id, ref: d.ref, ...d.data() }))));
};

export const getProducts = (manufactureRef: firebase.firestore.DocumentReference) => {
  return firestore()
    .collection(Collection.Products)
    .where('manufacture', '==', manufactureRef)
    .get()
    .then((snapshot) => Promise.resolve(snapshot.docs.map((p) => ({ id: p.id, ref: p.ref, ...p.data() } as Product))));
};

export const createOrderedDoc = (collection: Collection, doc: Doc & any): Promise<OrderedDoc> => {
  let collectionRef = firestore().collection(collection);
  let docRef = collectionRef.doc();
  return collectionRef
    .get()
    .then((snapshot) => Promise.resolve(snapshot.size))
    .then((count) => {
      let orderedDoc: OrderedDoc = { ...doc, order: count };
      return docRef.set(orderedDoc).then(() => Promise.resolve(orderedDoc));
    });
};

export const setDoc = (collection: Collection, doc: Doc & any, merge: boolean = true): Promise<string> => {
  let { id, ref, ...docData } = doc;
  let collectionRef = firestore().collection(collection);
  let docRef = id ? collectionRef.doc(id) : collectionRef.doc();
  return docRef.set(docData, { merge }).then(() => Promise.resolve(docRef.id!));
};

export const deleteDoc = (collection: Collection, id: string) => firestore().collection(collection).doc(id).delete();

export const orderDocs = (collection: Collection, docs: OrderedDoc[]) => {
  let db = firestore();
  let ref = db.collection(collection);
  let batch = db.batch();
  docs.forEach((doc) => {
    if (!doc.id) {
      return;
    }
    batch.set(
      ref.doc(doc.id),
      {
        order: doc.order,
      },
      { merge: true }
    );
  });
  return batch.commit();
};

export const containsProducts = (manufactureID: string): Promise<boolean> => {
  let db = firestore();
  return firestore()
    .collection(Collection.Products)
    .where('manufacture', '==', db.collection(Collection.Manufactures).doc(manufactureID))
    .limit(1)
    .get()
    .then((snapshot) => Promise.resolve(snapshot.size === 1));
};
