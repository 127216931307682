import React from 'react';
import styled from 'styled-components';
import {
  Divider,
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary,
  List as MuiList,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { ChevronDown as ExpandMoreIcon } from 'mdi-material-ui';
import { useHistory } from 'react-router';

import { Manufacture, Product } from '../types';

// #region styles

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: 'center';
`;

const Accordion = styled(MuiAccordion)`
  width: 100%;
`;

const List = styled(MuiList)`
  width: 100%;
`;

// #endregion

type Props = {
  manufacture: Manufacture;
  products: Product[];
};

export const Products: React.FC<Props> = (props) => {
  const { manufacture, products } = props;
  const history = useHistory();
  const [expanded, setExpanded] = React.useState<number | null>(0);

  const onChange = (panel: number) => (_: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : null);
  };

  function viewProduct(product: Product) {
    if (manufacture) {
      history.push(`/products/${manufacture.slug}/${product.slug}`);
    }
  }

  if (!manufacture) {
    return null;
  }

  let categories: { [category: string]: Product[] } = {};
  if (products) {
    for (let i = 0; i < products.length; i++) {
      let product = products[i];
      categories[product.category] = [...(categories[product.category] ?? []), product];
    }
  }
  return (
    <Container>
      {Object.keys(categories).map((category, i) => (
        <Accordion key={category} expanded={expanded === i} onChange={onChange(i)} square>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">{category}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              {categories[category].map((p, i) => (
                <React.Fragment key={p.id}>
                  <Divider component="li" />
                  <ListItem button onClick={() => viewProduct(p)}>
                    <ListItemText primary={p.name} secondary={p.type} />
                  </ListItem>
                </React.Fragment>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      ))}
    </Container>
  );
};
