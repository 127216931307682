import * as React from 'react';
import styled from 'styled-components';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { ArrowLeft as BackIcon } from 'mdi-material-ui';

import { getDocsFunc } from '../firebase/functions';
import { listAll } from '../firebase/storage';
import { Collection, Gallery as GalleryDefinition } from '../types';
import { GalleryList } from './GalleryList';
import { Gallery } from '../shared/Gallery';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const BackButton = styled(Button)`
  align-self: flex-start;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

const Galleries: React.FC = () => {
  const [galleries, setGalleries] = React.useState<GalleryDefinition[]>([]);
  const [gallery, setGallery] = React.useState<GalleryDefinition>();
  const history = useHistory();
  const routeMatch = useRouteMatch<{ slug?: string }>();
  const slug = routeMatch.params.slug;

  React.useEffect(() => {
    getDocsFunc(Collection.Galleries).then((docs) => {
      const results = docs as GalleryDefinition[];
      setGalleries(results);
      if (slug) {
        setGallery(results.find((g) => g.slug.toLowerCase() === slug.toLowerCase()));
      }
      results.forEach((g, i) => {
        listAll(`public/images/galleries/${g.id!}`).then((images) => {
          setGalleries((current) => {
            const updated = [...current];
            // Clear the image names so the gallery name is displayed instead.
            images.forEach((img) => {
              img.name = '';
            });
            updated[i].images = images;
            return updated;
          });
        });
      });
    });
  }, []);

  React.useEffect(() => {
    setGallery(slug ? galleries.find((g) => g.slug.toLowerCase() === slug.toLowerCase()) : undefined);
  }, [slug]);

  const onGalleryClicked = (g: GalleryDefinition) => {
    history.push(`/galleries/${g.slug}`);
  };

  const onBack = () => {
    history.push(`/galleries`);
  };

  return (
    <>
      {!gallery && <GalleryList galleries={galleries} onClick={onGalleryClicked} />}
      {gallery && (
        <Container>
          <BackButton color="primary" startIcon={<BackIcon />} onClick={onBack}>
            Galleries
          </BackButton>
          <Gallery gallery={gallery} />
        </Container>
      )}
    </>
  );
};

export default Galleries;
