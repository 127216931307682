import 'firebase/auth';
import * as React from 'react';
import app from 'firebase/app';
import { Redirect } from 'react-router-dom';
import { Avatar, Box, Button, Container, CssBaseline, Grid, Link, Typography, makeStyles } from '@material-ui/core';
import { LockOutline as LockOutlineIcon } from 'mdi-material-ui';
import Copyright from '../shared/Copyright';
import { UserContext } from '../context';

const Login: React.FC = () => {
  const classes = useStyles();
  const user = React.useContext(UserContext);

  const onLogin = () => {
    app.auth().signInWithPopup(new app.auth.GoogleAuthProvider());
  };

  if (user) {
    return <Redirect to="/admin/home" />;
  }
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlineIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Finesse Management Portal
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={onLogin}
            >
              <Box className={classes.logoBox}>
                <img className={classes.logo} src="./google.png" alt="Google" />
              </Box>
              <Box className={classes.textBox}>Sign In</Box>
            </Button>
          </Grid>
          <Grid item xs={12} className={classes.forgotPassItem}>
            <Link href="https://myaccount.google.com/intro/security" variant="body2" target="_blank">
              Forgot password?
            </Link>
          </Grid>
        </Grid>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    display: 'flex',
    margin: theme.spacing(3, 0, 2),
    width: '100%',
  },
  logoBox: {
    width: 30,
    backgroundColor: '#fff',
    borderRadius: 4,
    borderWidth: 1,
    borderColor: '#fff',
    borderStyle: 'solid',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: '100%',
    height: '100%',
  },
  textBox: {
    flex: 1,
    paddingRight: 30,
  },
  forgotPassItem: {
    textAlign: 'center',
  },
}));

export default Login;
