import React from 'react';
import { Video } from '../../types';

export const useForm = () => {
	const [dirty, setDirty] = React.useState(false);
  const [name, setName] = React.useState('');
	const [nameError, setNameError] = React.useState<string | null>(null);
	const [description, setDescription] = React.useState('');
	const [descriptionError, setDescriptionError] = React.useState<string | null>(null);
	const [embed, setEmbed] = React.useState('');
	const [embedError, setEmbedError] = React.useState<string | null>(null);
	const videoRef = React.useRef<Video | null>();

	const set = (video: Video | null) => {
		videoRef.current = video;
		if (video) {
			setName(video.name);
			setDescription(video.description);
			setEmbed(video.embed);
		} else {
			setName('');
			setDescription('');
			setEmbed('');
		}
	}

	const reset = () => {
		let original = videoRef.current;
		if (original) {
			setName(original.name);
			setDescription(original.description);
			setEmbed(original.embed);
		}
		setDirty(false);
	}

  const validate = (): boolean => {
		let valid = true;
		if (name.trim().length === 0) {
			setNameError('Name is required');
			valid = false;
		}
		if (embed.trim().length === 0) {
			setEmbedError('Embed is required');
			valid = false;
		}
		return valid;
	}

  return {
		name,
    nameField: {
			variant: 'outlined' as any,
			margin: 'dense' as any,
			fullWidth: true,
			label: 'Name',
			value: name,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value)
				setNameError(null);
				setDirty(true);
      },
      error: nameError != null,
			helperText: nameError
		},
		description,
    descriptionField: {
			variant: 'outlined' as any,
			margin: 'dense' as any,
			fullWidth: true,
			multiline: true,
			rows: 4,
			label: 'Description',
			value: description,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        setDescription(e.target.value)
				setDescriptionError(null);
				setDirty(true);
      },
      error: descriptionError != null,
			helperText: descriptionError
		},
		embed,
		embedField: {
			margin: 'dense' as any,
			variant: 'outlined' as any,
			fullWidth: true,
			multiline: true,
			rows: 4,
			label: 'Embed',
			value: embed,
			onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmbed(e.target.value)
				setEmbedError(null);
				setDirty(true);
      },
      error: embedError != null,
			helperText: embedError
		},
		dirty,
		set,
		reset,
    validate
  }
}