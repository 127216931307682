import * as React from 'react';
import { Card, CardContent, CardHeader, Divider } from '@material-ui/core';
import { MapMarker as MapMarkerIcon, Phone as PhoneIcon } from 'mdi-material-ui';

import { PageType } from '../types';
import { SplitPage } from '../shared/SplitPage';
import { CardTitle } from '../shared/CardTitle';

export const Contact: React.FC = () => {
  return (
    <SplitPage sideWidth="50%" type={PageType.Contact} icon={<PhoneIcon />}>
      <Card square>
        <CardHeader title={<CardTitle title="Location" icon={<MapMarkerIcon />} />} />
        <Divider />
        <CardContent>
          <iframe
            width="100%"
            height="450"
            frameBorder="0"
            style={{
              border: 0,
            }}
            src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCEckZO2DKTeIpe1Z_dUHDeIWBri8QumsQ&q=FINESSE+PAINTING+DECORATING+LTD"
            allowFullScreen={true}
          ></iframe>
        </CardContent>
      </Card>
    </SplitPage>
  );
};
