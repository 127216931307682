import React from 'react';

const useNameForm = () => {
	const [dirty, setDirty] = React.useState(false);
  const [name, setName] = React.useState('');
	const [nameError, setNameError] = React.useState<string | null>(null);
	const nameRef = React.useRef<string>();
	
	const set = (name: string) => {
		nameRef.current = name;
		setName(name);
	}

	const reset = () => {
		setName(nameRef.current ? nameRef.current : '');
		setDirty(false);
	}

  const validate = (): boolean => {
		let valid = true;
		if (!/^[a-zA-Z0-9]/g.test(name)) {
			setNameError('Name is required and must start with an alphanumeric character.');
			valid = false;
		}
		return valid;
	}

  return {
		name,
    nameField: {
			variant: 'outlined' as any,
			margin: 'dense' as any,
			fullWidth: true,
			label: 'Name',
			value: name,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value)
				setNameError(null);
				setDirty(true);
      },
      error: nameError != null,
      helperText: nameError
		},
		dirty,
		set,
		reset,
    validate
  }
}

export default useNameForm;