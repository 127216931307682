import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router';
import { Container, CssBaseline, Grid, Typography, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';

import Copyright from './Copyright';
import Menu from './Menu';
import { Jumbo } from './Jumbo';
import Snackbar from './Snackbar';

const LogoWrapper = styled.div`
  ${({ theme }) => `
    max-width: 300px;
    ${[theme.breakpoints.down('xs')]} {
      max-width: 250px;
      margin: 0 auto;
    }
  `}
`;

const PhoneNumber = styled(Typography)`
  text-align: right;
  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    text-align: center;
  }
`;

const Layout: React.FC = (props) => {
  const { children } = props;
  const classes = useStyles();
  const location = useLocation();
  const theme = useTheme();
  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Container maxWidth="lg" className={classes.header} component="header">
        <Grid container>
          <Grid item xs={12} sm={6}>
            <LogoWrapper>
              <img
                className={classes.logo}
                src={`${process.env.PUBLIC_URL}/images/logo.png`}
                alt="Finesse Architectural Coatings"
              />
            </LogoWrapper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <PhoneNumber variant={isXsDown ? 'h5' : 'h4'} color="primary">
              403-540-0895
            </PhoneNumber>
          </Grid>
          <div className={classes.logoContainer}></div>
        </Grid>
      </Container>
      <Menu />
      {location.pathname === '/' && <Jumbo />}
      <Container className={classes.main} maxWidth="lg" component="main">
        {children as any}
      </Container>
      <footer className={classes.footer}>
        <Copyright />
      </footer>
      <Snackbar />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 150,
  },
  logoContainer: {
    maxWidth: 300,
  },
  logo: {
    width: '100%',
    height: 'auto',
  },
  main: {
    padding: theme.spacing(2),
  },
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: 'auto',
    backgroundColor: theme.palette.grey[200],
  },
}));

export default Layout;
