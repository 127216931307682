import * as React from 'react';
import styled from 'styled-components';
import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';

import { DocumentsPassword } from './DocumentsPassword';

const UpdatesList: React.FC<{ items: string[] }> = ({ items }) => {
  return (
    <ul>
      {items.map((item, i) => (
        <li key={i}>{item}</li>
      ))}
    </ul>
  );
};

const Home: React.FC = () => {
  const v1Updates = ['New mobile friendly design.', 'New content management system.', 'Added Google authentication.'];

  return (
    <Container maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Card square>
            <CardHeader title="Updates" />
            <Divider />
            <CardContent>
              <List>
                <ListItem>
                  <ListItemText primary="Version 1.0" secondary={<UpdatesList items={v1Updates} />} />
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card square>
            <CardHeader title="Reset Documents Password" />
            <Divider />
            <CardContent>
              <DocumentsPassword />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Home;
