import 'firebase/functions';
import * as firebase from 'firebase/app';
import { useProduction } from './index';
import { Collection, Product } from '../types';

const baseURL = `${process.env.PUBLIC_URL}/api`;

function functions() {
  const functions = firebase.functions();
  if (!useProduction && window.location.hostname === 'localhost') {
    functions.useFunctionsEmulator('http://localhost:5000');
  }
  return functions;
}

export const cleanupImages = (collection: Collection, docID: string) => {
  const cleanupImages = functions().httpsCallable('cleanupContentImages');
  return cleanupImages({ collection, docID });
};

export const setDocumentsPassword = (password: string, confirmPassword: string) => {
  const setDocumentsPassword = functions().httpsCallable('setDocumentsPassword');
  return setDocumentsPassword({ password, confirmPassword });
};

export const getDocFunc = <T>(collection: Collection, docID: string | null, slug?: string | null): Promise<T> => {
  let url = `${baseURL}/doc?collection=${collection}`;
  if (docID) {
    url += `&id=${docID}`;
  }
  if (slug) {
    url += `&slug=${slug}`;
  }
  return fetch(url).then((rsp) => {
    if (rsp.status === 200) {
      return Promise.resolve((rsp.json() as unknown) as T);
    }
    return Promise.reject(rsp);
  });
};

export const getDocsFunc = <T>(collection: Collection, ordered?: boolean): Promise<T[]> => {
  let url = `${baseURL}/docs?collection=${collection}`;
  if (ordered === true) {
    url += `&ordered=true`;
  }
  return fetch(url).then((rsp) => {
    if (rsp.status === 200) {
      return Promise.resolve((rsp.json() as unknown) as T[]);
    }
    return Promise.reject(rsp);
  });
};

export const getProducts = (manufatureID: string) => {
  const url = `${baseURL}/products?id=${manufatureID}`;
  return fetch(url).then((rsp) => {
    if (rsp.status === 200) {
      return Promise.resolve((rsp.json() as unknown) as Product[]);
    }
    return Promise.reject(rsp);
  });
};
