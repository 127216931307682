import * as React from 'react';
import styled from 'styled-components';
import { Card as MuiCard, CardHeader, CardContent } from '@material-ui/core';

import { getDocsFunc } from '../firebase/functions';
import { Collection, Video } from '../types';

const Card = styled(MuiCard)`
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

const Embed = styled.div`
  text-align: center;
`;

export const Videos: React.FC = () => {
  const [videos, setVideos] = React.useState<Video[]>([]);

  React.useEffect(() => {
    getDocsFunc(Collection.Videos)
      .then((docs) => {
        setVideos((docs as Video[]).sort((a, b) => a.order - b.order));
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      {videos.map((video, i) => (
        <Card key={video.id} square>
          <CardHeader title={video.name} subheader={video.description} />
          <CardContent>
            <Embed dangerouslySetInnerHTML={{ __html: video.embed }} />
          </CardContent>
        </Card>
      ))}
    </>
  );
};
