import React, { useEffect } from 'react';
import { DialogContentText, Grid, TextField } from '@material-ui/core';
import slugify from 'slugify';
import { createOrderedDoc, setDoc } from '../../firebase/firestore';
import { SetMessageContext } from '../../context';
import { useForm } from './form';
import { Collection, MessageType, Video } from '../../types';
import BaseDialog from '../shared/BaseDialog';

type Props = {
  open: boolean;
  video: Video | null;
  onClose: () => void;
  onCompleted: (isNew: boolean) => void;
};

const VideoDialog: React.FC<Props> = (props) => {
  const { open, video: defaultVideo, onClose, onCompleted } = props;
  const setMessage = React.useContext(SetMessageContext);
  const form = useForm();
  const [busy, setBusy] = React.useState(false);
  const isNew = defaultVideo ? false : true;

  function onUpdateOrCreate() {
    console.log('onUpdateOrCreate: ' + isNew);
    if (!form.validate()) {
      return;
    }
    setBusy(true);
    let slug = slugify(form.name, { lower: true });
    let video: Video = {
      ...(defaultVideo ? defaultVideo : { order: -1 }),
      name: form.name,
      slug,
      description: form.description,
      embed: form.embed,
    };
    let update = ((isNew ? createOrderedDoc : setDoc) as unknown) as (
      collection: Collection,
      video: Video
    ) => Promise<void>;
    update(Collection.Videos, video)
      .then(() => {
        setBusy(false);
        onCompleted(isNew);
      })
      .catch(() => {
        setBusy(false);
        setMessage({
          type: MessageType.Error,
          text: `Failed to ${isNew ? 'create' : 'update'} video, please refresh the page and try again!`,
        });
      });
  }

  useEffect(() => {
    form.set(defaultVideo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultVideo]);

  return (
    <BaseDialog
      title={isNew ? 'New Video' : 'Update Video'}
      open={open}
      busy={busy}
      onClose={onClose}
      onOK={onUpdateOrCreate}
    >
      <React.Fragment>
        <DialogContentText>
          {`${isNew ? 'Enter the information of the new video' : 'Update video information of the'} and then click OK.`}
        </DialogContentText>
        <Grid container spacing={2}>
          <React.Fragment>
            <Grid item xs={12}>
              <TextField autoFocus={isNew ? true : false} disabled={busy} {...form.nameField} />
            </Grid>
            <Grid item xs={12}>
              <TextField disabled={busy} {...form.descriptionField} />
            </Grid>
            <Grid item xs={12}>
              <TextField disabled={busy} {...form.embedField} />
            </Grid>
          </React.Fragment>
        </Grid>
      </React.Fragment>
    </BaseDialog>
  );
};

export default VideoDialog;
