import * as React from 'react';
import styled from 'styled-components';
import { Card as MuiCard } from '@material-ui/core';

import { PageType } from '../types';
import { SplitPage } from '../shared/SplitPage';

const Card = styled(MuiCard)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Img = styled.img`
  margin: ${({ theme }) => theme.spacing(4, 0)};
`;

export const Home: React.FC = () => {
  return (
    <SplitPage type={PageType.Home}>
      <Card square>
        <Img src={`${process.env.PUBLIC_URL}/images/kci.jpg`} alt="Katilac Coatings Inc." style={{ width: 225 }} />
        <Img
          src={`${process.env.PUBLIC_URL}/images/contego.jpg`}
          alt="Contego International, Inc."
          style={{ width: 250 }}
        />
        <Img src={`${process.env.PUBLIC_URL}/images/ica.jpg`} alt="ICA Group" style={{ height: 150 }} />
      </Card>
    </SplitPage>
  );
};
