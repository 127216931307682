import * as React from 'react';
import styled from 'styled-components';

import { Award, Collection, Gallery as GalleryDefinition } from '../types';
import { listAll } from '../firebase/storage';
import { getDocsFunc } from '../firebase/functions';
import { Gallery } from '../shared/Gallery';

// #region styles

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

// #endregion

const Awards: React.FC = () => {
  const [awards, setAwards] = React.useState<GalleryDefinition>();

  React.useEffect(() => {
    let galleryDef: GalleryDefinition = {
      name: 'Awards',
      slug: 'awards',
      order: 0,
      images: [],
    };
    let awards: Award[];
    getDocsFunc(Collection.Awards)
      .then((docs) => {
        awards = (docs as Award[]).sort((a, b) => a.order - b.order);
        return listAll('public/images/awards');
      })
      .then((images) => {
        awards.forEach((award) => {
          const img = images.find((image) => award.filename === image.name);
          if (img) {
            galleryDef.images!.push({ ...img, name: award.name, description: award.description });
          }
        });
        // images.forEach((img) => {
        //   const award = awards.find((a) => a.filename === img.name);
        //   if (award) {
        //     galleryDef.images!.push({ ...img, name: award.name, description: award.description });
        //   }
        // });
        setAwards(galleryDef);
      });
  }, []);

  if (!awards) {
    return null;
  }

  return (
    <Container>
      <Gallery gallery={awards} />
    </Container>
  );
};

export default Awards;
