import * as React from 'react';
import { DialogContentText, TextField } from '@material-ui/core';
import slugify from 'slugify';
import useNameForm from '../shared/nameForm';
import { createOrderedDoc } from '../../firebase/firestore';
import { Collection, MessageType, NamedDoc, OrderedNamedDoc } from '../../types';
import { SetMessageContext } from '../../context';
import BaseDialog from '../shared/BaseDialog';

type Props = {
  open: boolean;
  onClose: () => void;
  onCreated: (manufacture: OrderedNamedDoc) => void;
};

const CreateManufactureDialog: React.FC<Props> = (props) => {
  const { open, onClose, onCreated } = props;
  const form = useNameForm();
  const [busy, setBusy] = React.useState(false);
  const setMessage = React.useContext(SetMessageContext);

  const create = () => {
    if (!form.validate()) {
      return;
    }
    setBusy(true);
    let slug = slugify(form.name, { lower: true });
    let manufacture: NamedDoc = { name: form.name, slug };
    return createOrderedDoc(Collection.Manufactures, manufacture)
      .then((orderedDoc) => {
        setBusy(false);
        onCreated({ ...manufacture, ...orderedDoc });
      })
      .catch(() => {
        setBusy(false);
        setMessage({
          type: MessageType.Error,
          text: 'Failed to create manufacture, please refresh the page and try again!',
        });
      });
  };

  return (
    <BaseDialog title="Create New Manufacture" open={open} busy={busy} onClose={onClose} onOK={create}>
      <DialogContentText>Enter the name of your new manufacture and then click create.</DialogContentText>
      <TextField disabled={busy} {...form.nameField} />
    </BaseDialog>
  );
};

export default CreateManufactureDialog;
