import * as React from 'react';
import styled from 'styled-components';
import { Box, Button as MuiButton, Grid, TextField, Typography, makeStyles } from '@material-ui/core';
import { Information } from 'mdi-material-ui';
import { getDoc, setDoc } from '../../firebase/firestore';
import { cleanupImages } from '../../firebase/functions';
import { SetMessageContext } from '../../context';
import { Collection, MessageType, Page, PageType } from '../../types';
import Select from '../shared/Select';
import { Editor, EditorRef } from '../shared/editor/Editor';
import slugify from 'slugify';

// #region styles

const GridContainer = styled(Grid)`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const GridOptions = styled(Grid)`
  flex: 0;
  display: flex;
  align-items: center;
`;

const InfoIcon = styled(Information)`
  font-size: 16px;
  margin-right: ${({ theme }) => theme.spacing(0.5)}px;
`;

const GridEditor = styled(Grid)`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const ContentEditor = styled(Editor)`
  flex: 1;
`;

const Button = styled(MuiButton)`
  margin-left: ${({ theme }) => theme.spacing(2)}px;
  height: 36px;
`;

const PageSelect = styled(Select)`
  min-width: 250px;
  margin-right: auto;
`;

// //#endregion

const Pages: React.FC = () => {
  const setMessage = React.useContext(SetMessageContext);
  const [busy, setBusy] = React.useState(false);
  const [dirty, setDirty] = React.useState(false);
  const [pageType, setPageType] = React.useState<PageType>(PageType.Home);
  const [header, setHeader] = React.useState('');
  const [content, setContent] = React.useState('');
  const editor = React.useRef<EditorRef>(null);
  const cleanPage = React.useRef<Page>();
  const isInfoPage = pageType !== PageType.Home && pageType !== PageType.Contact && pageType !== PageType.Slogan;

  function onHeaderChange(value: string) {
    setHeader(value);
    setDirty(true);
  }

  function onContentChange() {
    setDirty(true);
  }

  function onUpdate() {
    setBusy(true);
    let page: Page = {
      id: pageType,
      name: header,
      slug: slugify(header),
      content: editor.current!.getJson(),
    };
    setDoc(Collection.Pages, page)
      .then(() => {
        setMessage({ type: MessageType.Success, text: 'Page updated successfully!' });
        setDirty(false);
      })
      .catch(() => {
        setMessage({ type: MessageType.Error, text: 'An error occured, please refresh the page and try again.' });
      })
      .finally(() => {
        setBusy(false);
      });
  }

  function onCancel() {
    if (window.confirm('Are you sure you want to cancel your changes?')) {
      setHeader(cleanPage.current!.name);
      setContent(cleanPage.current!.content);
      cleanupImages(Collection.Pages, pageType);
    }
  }

  React.useEffect(() => {
    getDoc(Collection.Pages, pageType)
      .then((doc) => {
        let page = doc as Page;
        cleanPage.current = page;
        setHeader(page.name ? page.name : '');
        setContent(page.content ? page.content : '');
      })
      .catch(() => {
        setHeader('');
        setContent('');
        setMessage({
          type: MessageType.Error,
          text: 'Failed to load page content, please refresh the page and try again.',
        });
      });
  }, [pageType, setMessage]);

  return (
    <GridContainer container spacing={2}>
      <GridOptions item xs={12}>
        <PageSelect
          margin="dense"
          label="Page"
          value={pageType}
          values={[
            { value: PageType.Home, text: 'Home' },
            { value: PageType.Slogan, text: 'Slogan' },
            { value: PageType.Info1, text: 'Information 1' },
            { value: PageType.Info2, text: 'Information 2' },
            { value: PageType.Info3, text: 'Information 3' },
            { value: PageType.Info4, text: 'Information 4' },
            { value: PageType.Info5, text: 'Information 5' },
            { value: PageType.Info6, text: 'Information 6' },
            { value: PageType.Contact, text: 'Contact Information' },
          ]}
          onChange={(value: PageType) => setPageType(value)}
        />
        <Button variant="contained" disableElevation disabled={busy || !dirty} onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="contained" disableElevation color="primary" disabled={busy || !dirty} onClick={onUpdate}>
          Save Changes
        </Button>
      </GridOptions>
      <GridOptions item xs={12}>
        <TextField
          variant="outlined"
          margin="dense"
          fullWidth
          label="Header"
          disabled={busy}
          value={header}
          onChange={(e) => onHeaderChange(e.target.value)}
        />
      </GridOptions>
      <GridOptions item xs={12}>
        <InfoIcon />
        <Typography variant="caption">
          {isInfoPage && <>Leave the header empty to hide this page. </>}Changes can take up to 5 minutes before they go
          live.
        </Typography>
      </GridOptions>
      <GridEditor item xs={12}>
        <ContentEditor
          ref={editor}
          value={content}
          imagePath={`public/images/pages/${pageType}`}
          onChange={onContentChange}
        />
      </GridEditor>
    </GridContainer>
  );
};

export default Pages;
