import { createMuiTheme } from '@material-ui/core';

export const theme = createMuiTheme({
  overrides: {
    MuiTypography: {
      gutterBottom: {
        marginBottom: 16,
      },
    },
  },
  typography: {
    fontFamily: [
      'Roboto',
      'Arial',
      '"Helvetica Neue"',
      'sans-serif',
      '"Segoe UI"',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h6: {
      fontWeight: 400,
    },
  },
  palette: {
    primary: {
      dark: '#351717',
      main: '#4c2222',
      light: '#6f4e4e',
      contrastText: '#ffffff',
    },
    success: {
      light: '#81c784',
      main: '#4caf50',
      dark: '#388e3c',
      contrastText: '#ffffff',
    },
  },
});
