import 'firebase/auth';
import React from 'react';
import app from 'firebase/app';
import { Redirect, Route } from 'react-router-dom';

import { LayoutRouteProps } from '../types';
import { UserContext } from '../context';
import Loading from './Loading';

const LayoutRoute: React.FC<LayoutRouteProps> = (props) => {
  const { layout: Layout, authorized, title, children } = props;
  const user = React.useContext(UserContext);
  if (authorized) {
    if (user === null) {
      return <Redirect to={{ pathname: '/admin', state: { from: props.location } }} />;
    }
    if (!user) {
      return <Loading />;
    }
    // Documents account.
    if (user.uid === 'ry3GdjAz7mfVr7yOhNuMAVPhNQq2') {
      app.auth().signOut();
      return <Loading />;
    }
  }
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Route {...props}>
      <Layout title={title}>{children}</Layout>
    </Route>
  );
};

export default LayoutRoute;
