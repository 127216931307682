import * as React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Card, CardProps, CardHeader, CardContent, Divider } from '@material-ui/core';

import { SetMessageContext } from '../context';
import { getDocFunc } from '../firebase/functions';
import { Collection, MessageType, Page as PageDefinition, PageType } from '../types';
import { RenderDraft } from './RenderDraft';
import { CardTitle } from '../shared/CardTitle';

type Props = {
  type?: PageType;
  hideHeader?: boolean;
  icon?: React.ReactNode;
} & CardProps;

export const Page: React.FC<Props> = (props) => {
  let { type, hideHeader, icon, ...cardProps } = props;
  const setMessage = React.useContext(SetMessageContext);
  const route = useRouteMatch<{ slug?: string }>();
  const { slug } = route.params;
  const [page, SetPage] = React.useState<PageDefinition>();

  React.useEffect(() => {
    if (!type && !slug) {
      return;
    }
    getDocFunc(Collection.Pages, (type ?? null) as string | null, slug ?? null)
      .then((doc) => {
        SetPage(doc as PageDefinition);
      })
      .catch((error) => {
        console.log(error);
        setMessage({
          type: MessageType.Error,
          text: 'An error occured! Please reload the page and try again.',
          duration: 15000,
        });
      });
  }, [type, slug]);

  return (
    <Card square {...cardProps}>
      {page && hideHeader !== true && page.name.trim().length > 0 && (
        <>
          <CardHeader title={<CardTitle title={page.name} icon={icon} />} />
          <Divider />
        </>
      )}
      {page && (
        <CardContent>
          <RenderDraft content={page.content} />
        </CardContent>
      )}
    </Card>
  );
};
