import * as React from 'react';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import {
  FormatBold as FormatBoldIcon,
  FormatItalic as FormatItalicIcon,
  FormatUnderline as FormatUnderlinedIcon,
} from 'mdi-material-ui';
import { EditorState } from 'draft-js';

var INLINE_STYLES = [
  { label: 'Bold', style: 'BOLD' },
  { label: 'Italic', style: 'ITALIC' },
  { label: 'Underline', style: 'UNDERLINE' },
];

type Props = {
  editorState: EditorState;
  onToggle: (inlineStyle: string) => void;
};

export const InlineStyleControls: React.FC<Props> = (props) => {
  const { editorState, onToggle } = props;
  const formats: string[] = [];
  const current = editorState.getCurrentInlineStyle();
  INLINE_STYLES.forEach((style) => {
    if (current.has(style.style)) {
      formats.push(style.style);
    }
  });

  const onChange = (e: React.MouseEvent<HTMLElement, MouseEvent>, values: string[]) => {
    e.preventDefault();
    let styles = formats.filter((element) => !values.includes(element));
    if (styles.length === 0) {
      styles = values.filter((element) => !formats.includes(element));
    }
    if (styles.length > 0) {
      onToggle(styles[0]);
    }
  };

  return (
    <ToggleButtonGroup value={formats} onChange={onChange} aria-label="text formatting">
      <ToggleButton value={INLINE_STYLES[0].style} aria-label="bold">
        <FormatBoldIcon />
      </ToggleButton>
      <ToggleButton value={INLINE_STYLES[1].style} aria-label="italic">
        <FormatItalicIcon />
      </ToggleButton>
      <ToggleButton value={INLINE_STYLES[2].style} aria-label="underlined">
        <FormatUnderlinedIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
