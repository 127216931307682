import * as React from 'react';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import { Close as CloseIcon, CloudUpload as CloudUploadIcon } from 'mdi-material-ui';
import { uuid } from '../shared/utils';
import grey from '@material-ui/core/colors/grey';
import FileIcon from './FileIcon';

type Props = {
  multiple?: boolean;
  accept?: string;
  dragText?: string;
  onUpload: (uploads: Upload[]) => void;
  showClearAll?: boolean;
  setClearAll?: (func: () => void) => void;
  allowClear?: boolean;
};

export enum UploadState {
  Idle,
  Uploading,
  Completed,
  Failed,
  Cancelled,
}

export type Upload = {
  id: string;
  file: File;
  filename?: string;
  progress: number;
  uploadState: UploadState;
  prevState: UploadState;
  set: (values: { uploadState?: UploadState; progress?: number; text?: string }) => void;
  cancel?: () => boolean;
  text?: string;
};

const FileUpload: React.FC<Props> = (props) => {
  const { onUpload, showClearAll, setClearAll } = props;
  const classes = useStyles();
  const [uploads, setUploads] = React.useState<Upload[]>([]);
  let fileUploadRef: HTMLInputElement | null;

  const triggerUpload = () => {
    if (fileUploadRef != null) {
      fileUploadRef.click();
    }
  };

  const validateUploadState = (prevState: UploadState, state: UploadState) => {
    if (prevState > UploadState.Uploading && prevState !== state) {
      throw Error('State cannot be changed once set to Completed, Failed or Cancelled.');
    }
    if (prevState === UploadState.Uploading && state < UploadState.Uploading) {
      throw Error('State can only be set to Completed, Failed or Cancelled once set to Uploading.');
    }
  };

  const handleFiles = (fileList: FileList | null) => {
    setUploads((currentUploads) => {
      let files = [...currentUploads];
      if (fileList) {
        for (let i = 0; i < fileList.length; i++) {
          let file = fileList[i];
          files.push({
            id: uuid(),
            file,
            progress: 0,
            uploadState: UploadState.Idle,
            prevState: UploadState.Idle,
            set: function (values) {
              if (values.uploadState) {
                validateUploadState(this.prevState, this.uploadState);
                this.uploadState = values.uploadState;
              }
              if (values.progress) {
                this.progress = values.progress;
              }
              if (values.text) {
                this.text = values.text;
              }
              this.prevState = this.uploadState;
              setUpload(this);
            },
          });
        }
      }
      return files;
    });
  };

  const filesAdded = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    handleFiles(e.target.files);
  };

  const setUpload = (upload: Upload) => {
    setUploads((currentUploads) => currentUploads.map((u) => (u.id === upload.id ? upload : u)));
  };

  const onDrop = (e: React.DragEvent<Element>) => {
    e.preventDefault();
    handleFiles(e.dataTransfer.files);
  };

  const clear = (upload: Upload) => {
    switch (upload.uploadState) {
      case UploadState.Uploading:
        if (upload.cancel) {
          upload.cancel();
        }
        break;
      default:
        // Clear upload.
        setUploads((currentUploads) => currentUploads.filter((u) => u.id !== upload.id));
        break;
    }
  };

  const clearAll = () => {
    setUploads([]);
  };

  React.useEffect(() => {
    if (setClearAll) {
      setClearAll(clearAll);
    }
  }, [setClearAll]);

  React.useEffect(() => {
    let idleUploads = uploads.filter((u) => u.uploadState === UploadState.Idle);
    if (idleUploads.length === 0) {
      return;
    }
    if (onUpload) {
      onUpload(uploads);
    }
  }, [uploads, onUpload]);

  const uploadItem = (upload: Upload, index: number) => {
    let backgroundClass;
    switch (upload.uploadState) {
      case UploadState.Completed:
        backgroundClass = classes.success;
        break;
      case UploadState.Cancelled:
        backgroundClass = classes.warning;
        break;
      case UploadState.Failed:
        backgroundClass = classes.error;
        break;
    }
    return (
      <React.Fragment key={index}>
        {index !== 0 && (
          <ListItem>
            <ListItemText>
              <Divider component="div" />
            </ListItemText>
          </ListItem>
        )}
        <ListItem>
          <ListItemAvatar>
            <Avatar className={backgroundClass}>
              <FileIcon filename={upload.file.name} className={classes.fileIcon} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={upload.file.name} secondary={upload.text} />
          {props.allowClear !== false && (
            <ListItemSecondaryAction>
              <IconButton edge="end" onClick={() => clear(upload)}>
                <CloseIcon />
              </IconButton>
            </ListItemSecondaryAction>
          )}
        </ListItem>
        <ListItem>
          <ListItemText>
            <LinearProgress
              variant="determinate"
              value={upload.progress}
              classes={{ barColorPrimary: backgroundClass }}
            />
          </ListItemText>
        </ListItem>
      </React.Fragment>
    );
  };

  let clearAllItem;
  if (
    showClearAll &&
    uploads.filter((u) => u.uploadState !== UploadState.Idle && u.uploadState !== UploadState.Uploading).length > 0
  ) {
    clearAllItem = (
      <ListItem>
        <ListItemText style={{ textAlign: 'center' }}>
          <Button onClick={clearAll}>Clear All</Button>
        </ListItemText>
      </ListItem>
    );
  }

  return (
    <>
      {uploads.length > 0 && (
        <List>
          {uploads.map(uploadItem)}
          {clearAllItem}
        </List>
      )}
      {uploads.length === 0 && (
        <Box
          className={classes.uploadBox}
          onDragOver={(e) => {
            e.preventDefault();
          }}
          onDrop={onDrop}
        >
          <div className={classes.noMargin}>
            <CloudUploadIcon fontSize="large" />
          </div>
          <h4 className={clsx(classes.noMargin, classes.bottomMargin)}>
            {props.dragText ? props.dragText : props.multiple ? 'Drag Files Here' : 'Drag File Here'}
          </h4>
          <h5 className={classes.noMargin}>
            or{' '}
            <Button color="primary" onClick={triggerUpload}>
              Browse Computer
            </Button>
          </h5>
          <input
            ref={(ref) => (fileUploadRef = ref)}
            type="file"
            hidden
            multiple={props.multiple ? true : false}
            accept={props.accept}
            onChange={filesAdded}
          />
        </Box>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  uploadBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    verticalAlign: 'middle',
    flexDirection: 'column',
    width: '100%',
    height: 200,
    borderWidth: 1,
    borderColor: grey[500],
    borderStyle: 'dashed',
    borderRadius: 5,
  },
  noMargin: {
    margin: 0,
  },
  bottomMargin: {
    marginBottom: theme.spacing(1),
  },
  success: {
    backgroundColor: theme.palette.success.main,
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
  },
  error: {
    backgroundColor: theme.palette.error.main,
  },
  fileIcon: {
    marginLeft: 2,
    marginTop: -2,
  },
}));

export default FileUpload;
