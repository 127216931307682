import * as React from 'react';
import { Button, Grid, useTheme } from '@material-ui/core';
import { Pencil as PencilIcon, Delete as DeleteIcon, Seal as AwardIcon } from 'mdi-material-ui';
import MaterialTable from 'material-table';
import { useHistory } from 'react-router';
import { deleteDoc, observeCollection } from '../../firebase/firestore';
import { SetMessageContext } from '../../context';
import { Award, Collection, MessageType } from '../../types';
import CreateAwardDialog from './CreateAwardDialog';
import OrderDialog from '../shared/OrderDialog';

const Awards: React.FC = () => {
  const theme = useTheme();
  const history = useHistory();
  const setMessage = React.useContext(SetMessageContext);
  const [createDialogOpen, setCreateDialogOpen] = React.useState(false);
  const [orderDialogOpen, setOrderDialogOpen] = React.useState(false);
  const [awards, setAwards] = React.useState<Award[]>([]);

  const editAward = (award: Award) => {
    history.push(`/admin/awards/${award.slug}`);
  };

  const deleteAward = (award: Award) => {
    if (!window.confirm('Are you sure you want to delete this award?')) {
      return;
    }
    deleteDoc(Collection.Awards, award.id!)
      .then(() => {
        setAwards((current) => [...current.filter((a) => a.id !== award.id)]);
        setMessage({ type: MessageType.Success, text: 'Award deleted successfully!' });
      })
      .catch(() => {
        setMessage({ type: MessageType.Error, text: 'Delete award failed, please refresh the page and try again!' });
      });
  };

  React.useEffect(() => {
    observeCollection(Collection.Awards, true, (docs) => {
      setAwards(docs as Award[]);
    });
  }, []);

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            disableElevation
            color="primary"
            style={{ marginRight: theme.spacing(2) }}
            onClick={() => setCreateDialogOpen(true)}
          >
            Create Award
          </Button>
          <Button variant="contained" disableElevation onClick={() => setOrderDialogOpen(true)}>
            Order Awards
          </Button>
        </Grid>
        <Grid item xs={12}>
          <MaterialTable
            title="Awards"
            style={{ borderRadius: 0 }}
            columns={[
              { title: 'Name', field: 'name' },
              { title: 'Description', field: 'description' },
              { hidden: true, field: 'order', sorting: true, defaultSort: 'asc' },
            ]}
            data={awards}
            options={{
              headerStyle: {
                paddingLeft: theme.spacing(2),
              },
              pageSize: 10,
              pageSizeOptions: [10, 20, 50],
            }}
            localization={{
              body: {
                emptyDataSourceMessage: 'No awards to display',
              },
            }}
            actions={[
              {
                icon: () => <PencilIcon />,
                tooltip: 'Edit Award',
                onClick: (_, award) => editAward(award as Award),
              },
              {
                icon: () => <DeleteIcon />,
                tooltip: 'Delete Award',
                onClick: (_, award) => deleteAward(award as Award),
              },
            ]}
          />
        </Grid>
      </Grid>
      <CreateAwardDialog
        open={createDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
        onCreated={(award) => editAward(award)}
      />
      <OrderDialog
        title="Order Awards"
        open={orderDialogOpen}
        onClose={() => setOrderDialogOpen(false)}
        collection={Collection.Awards}
        items={awards}
        icon={<AwardIcon />}
      />
    </React.Fragment>
  );
};

export default Awards;
