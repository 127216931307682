import React from 'react';
import { getAllDocs } from '../firebase/firestore';
import { Collection, Manufacture } from '../types';

export const ManufacturesContext = React.createContext<Manufacture[]>([]);

const ManufacturesProvider: React.FC = (props) => {
  const [manufactures, setManufactures] = React.useState<Manufacture[]>([]);

  React.useEffect(() => {
    getAllDocs(Collection.Manufactures).then((docs) => setManufactures(docs as Manufacture[]));
  }, []);

  return <ManufacturesContext.Provider value={manufactures}>{props.children}</ManufacturesContext.Provider>;
};

export default ManufacturesProvider;
