import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as firebase from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyCMmQvVsIGg7an3kStF1TdKbJl4pn1ed48',
  authDomain: 'finessepaint.com',
  databaseURL: 'https://finesse-paint.firebaseio.com',
  projectId: 'finesse-paint',
  storageBucket: 'finesse-paint.appspot.com',
  messagingSenderId: '763943271606',
  appId: '1:763943271606:web:6fa03505dde4d57a777a17',
  measurementId: 'G-E5C6B2X7R9',
};
firebase.initializeApp(firebaseConfig);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
