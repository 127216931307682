import * as React from 'react';
import styled from 'styled-components';
import { Button, Grid, TextField } from '@material-ui/core';

import { setDocumentsPassword } from '../../firebase/functions';
import { SetMessageContext } from '../../context';
import { MessageType } from '../../types';

const ButtonGrid = styled(Grid)`
  text-align: right;
`;

export const DocumentsPassword = () => {
  const [busy, setBusy] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [passwordError, setPasswordError] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [confirmPasswordError, setConfirmPasswordError] = React.useState('');
  const setMessage = React.useContext(SetMessageContext);

  const onPasswordChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    setPasswordError('');
    setConfirmPasswordError('');
  };

  const onConfirmPasswordChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
    setPasswordError('');
    setConfirmPasswordError('');
  };

  const onUpdate = () => {
    let hasError = false;
    if (password.length === 0) {
      setConfirmPasswordError('Password required');
      hasError = true;
    }
    if (confirmPassword.length === 0) {
      setConfirmPasswordError('Retype the password');
      hasError = true;
    }
    if (hasError) {
      return;
    }
    setBusy(true);
    setDocumentsPassword(password, confirmPassword)
      .then(() => {
        setPassword('');
        setConfirmPassword('');
        setMessage({ type: MessageType.Success, text: 'Document password updated successfully!' });
      })
      .catch((error) => {
        setMessage({
          type: MessageType.Error,
          text: error?.message ?? 'Password failed to update, please reload the page and try again.',
        });
      })
      .finally(() => {
        setBusy(false);
      });
  };

  return (
    <form>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            type="password"
            variant="outlined"
            margin="dense"
            fullWidth
            label="Password"
            value={password}
            onChange={onPasswordChanged}
            helperText={passwordError}
            error={passwordError.length > 0}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            type="password"
            variant="outlined"
            margin="dense"
            fullWidth
            label="Retype Password"
            value={confirmPassword}
            onChange={onConfirmPasswordChanged}
            helperText={confirmPasswordError}
            error={confirmPasswordError.length > 0}
          />
        </Grid>
        <ButtonGrid item xs={12}>
          <Button
            variant="contained"
            disableElevation
            color="primary"
            onClick={onUpdate}
            disabled={busy || password.length === 0 || confirmPassword.length === 0}
          >
            Change Password
          </Button>
        </ButtonGrid>
      </Grid>
    </form>
  );
};
