import 'firebase/auth';
import * as React from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Divider, Paper as MuiPaper, Tabs, Tab } from '@material-ui/core';

import { getDocsFunc, getProducts } from '../firebase/functions';
import { Collection, Manufacture, MessageType, Product as ProductDefinition } from '../types';
import { TabPanel as Panel } from '../shared/TabPanel';
import { SetMessageContext } from '../context';
import { Products } from './Products';
import { Product } from './Product';

// #region styles

const Paper = styled(MuiPaper)`
  border: 1px solid ${({ theme }) => theme.palette.grey[300]};
`;

const TabPanel = styled(Panel)`
  background-color: ${({ theme }) => theme.palette.background.default};
`;

// #endregion

export const Manufactures: React.FC = () => {
  const [manufactures, setManufactures] = React.useState<Manufacture[]>();
  const [tab, setTab] = React.useState(-1);
  const history = useHistory();
  const routeMatch = useRouteMatch<{ manufacture?: string; slug?: string }>();
  const { manufacture, slug } = routeMatch.params;
  const [products, setProducts] = React.useState<ProductDefinition[]>([]);
  const [product, setProduct] = React.useState<ProductDefinition>();
  const setMessage = React.useContext(SetMessageContext);

  const onChange = (tabIndex: number) => {
    if (manufactures) {
      history.push(`/products/${manufactures[tabIndex].slug}`);
    }
  };

  React.useEffect(() => {
    getDocsFunc(Collection.Manufactures)
      .then((docs) => {
        setManufactures((docs as Manufacture[]).sort((a, b) => a.order - b.order));
      })
      .catch(() => {
        setMessage({
          type: MessageType.Error,
          text: 'An error occured! Please reload the page and try again.',
          duration: 15000,
        });
      });
  }, []);

  React.useEffect(() => {
    if (manufactures) {
      const index = manufacture ? manufactures.findIndex((m) => m.slug === manufacture) : -1;
      if (index < 0 || index >= manufactures.length) {
        onChange(0);
        return;
      }
      setTab(index);
      if (manufacture) {
        const manufactureDoc = manufactures.find((m) => m.slug === manufacture.toLowerCase());
        if (manufactureDoc?.id) {
          getProducts(manufactureDoc.id).then((products) => {
            setProducts(products);
          });
        }
      }
    }
  }, [manufactures, manufacture]);

  React.useEffect(() => {
    setProduct(slug ? products.find((p) => p.slug === slug.toLowerCase()) : undefined);
  }, [products, slug]);

  return (
    <>
      {manufactures && tab > -1 && tab < manufactures.length && !slug && (
        <Paper square>
          <Tabs
            value={tab}
            onChange={(_, tabIndex) => onChange(tabIndex)}
            aria-label="document categories"
            indicatorColor="primary"
            textColor="primary"
          >
            {manufactures.map((manufacture) => (
              <Tab key={manufacture.id} label={manufacture.name} />
            ))}
          </Tabs>
          <Divider />
          {manufactures.map((manufacture, index) => (
            <TabPanel key={manufacture.id} value={tab} index={index}>
              <Products manufacture={manufacture} products={products} />
            </TabPanel>
          ))}
        </Paper>
      )}
      {product && <Product product={product} onBack={() => onChange(tab)} />}
    </>
  );
};
