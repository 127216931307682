import React from 'react';
import {
  Grid,
  TextField
} from '@material-ui/core';
import { useForm } from './form';

type Props = {
  form: ReturnType<typeof useForm>,
	busy: boolean,
	autoFocus?: boolean
}

const AwardForm: React.FC<Props> = (props) => {
  const { form, busy } = props;
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <TextField autoFocus={props.autoFocus} disabled={busy} {...form.nameField} />
      </Grid>
      <Grid item xs={12}>
        <TextField disabled={busy} {...form.descriptionField} />
      </Grid>
    </React.Fragment>
  );
};

export default AwardForm;